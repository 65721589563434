import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './Users.module.scss';
import get from '../../../services/axios/get';
import del from '../../../services/axios/delete';
import patch from '../../../services/axios/patch';
import { useAppDispatch } from '../../../store/hooks';
import { customAlert } from '../../../store/actions/alert';
import post from '../../../services/axios/post';
import { Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { CgMenu } from 'react-icons/cg';
import Loader from '../../UI/Loader/Loader';
import { FaPlus } from 'react-icons/fa';
import Modal from '../../../containers/Modal/Modal';
import UserForm from './UserForm/UserForm';
import User from '../../../models/user';
import Alert from '../../UI/Alert/Alert';
import { Entity, EntityState } from '../../Loads/LoadForm/LoadForm';

interface UsersProps { };

const Users: React.FC<UsersProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [users, setUser] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<{ [key: string]: boolean }>({});
  const [showEditUser, setShowEditUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [clients, setClients] = useState<EntityState<Entity>>({ data: [], isLoading: true });

  const createNewUser = (user: Omit<User, 'id' | 'isSuperAdmin' | 'activationCode'>) => {
    post(
      '/user',
      user,
      () => {
        if (isMounted) {
          setShowCreateUser(false);
          setIsLoading(true);
          getUsers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
          setShowCreateUser(false);
        }
      }
    )
  }

  const generateActivationCode = (userId: string) => {
    setIsLoading(true);
    post(
      `/user/activation-code`,
      { userId },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getUsers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteUser = (userId: string) => {
    del(
      `/user/${userId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getUsers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editUser = (userId: string, userData: Omit<User, 'id' | 'isSuperAdmin' | 'activationCode'>) => {
    patch(
      `/user/${userId}`,
      userData,
      () => {
        if (isMounted) {
          setShowEditUser(false);
          setIsLoading(true);
          getUsers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getUsers = useCallback(
    () => {
      get(
        '/user',
        {},
        (res) => {
          if (isMounted) {
            setUser(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  const getClients = useCallback(
    () => {
      get(
        '/client',
        {},
        (res) => {
          if (isMounted.current) {
            setClients({ data: res.data, isLoading: false });
          }
        },
        () => {
          if (isMounted.current) {
            setIsBroken(true);
          }
        });
    },
    []
  );

  const toggleDropdown = (userId: string) => {
    setDropdownOpen(prev => ({
      ...prev,
      [userId]: !prev[userId]
    }));
  };

  const handleEditClick = (user: User) => {
    setSelectedUser(user);
    setShowEditUser(true);
  };

  const handleDeleteClick = (user: User) => {
    setUserToDelete(user);
    setShowDeleteConfirm(true);
  };

  const renderUsers = () => {
    return users.map((user: User, index: number) => {
      const shadeClass = index % 2 === 0 ? 'even' : 'odd';

      return <div key={user.id} className={[classes['user'], classes[shadeClass]].join(' ')}>
        <div>{user.firstName} {user.lastName}</div>
        <div>{user.userType}</div>
        <div>{user.email}</div>
        <div>{user.phoneNumber}</div>
        <div>{user.clientId ? clients.data.find(client => client.id === user.clientId)?.alias : '-'}</div>
        <div>{user.activationCode}</div>

        <div className={classes['menu']}>
          <Dropdown isOpen={dropdownOpen[user.id] || false} toggle={() => toggleDropdown(user.id)} direction={'down'} className={classes['dropdown']}>
            <DropdownToggle><CgMenu size={'16'} /></DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleEditClick(user)}>
                Edit
              </DropdownItem>
              <DropdownItem onClick={() => generateActivationCode(user.id)}>
                Invite
              </DropdownItem>
              <DropdownItem onClick={() => handleDeleteClick(user)}>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    })
  }

  useEffect(() => {
    getUsers();
    getClients();

    return () => {
      isMounted.current = false;
    }
  }, [getUsers, getClients])

  return (
    <Container>
      {showCreateUser ? (
        <Modal close={() => setShowCreateUser(false)}>
          <UserForm close={() => setShowCreateUser(false)} createUser={createNewUser} user={null} clients={clients} />
        </Modal>
      ) : null}

      {showEditUser && selectedUser ? (
        <Modal close={() => setShowEditUser(false)}>
          <UserForm
            close={() => setShowEditUser(false)}
            createUser={(userData) => editUser(selectedUser.id, userData)}
            user={selectedUser}
            clients={clients}
          />
        </Modal>
      ) : null}

      {showDeleteConfirm && userToDelete ? (
        <Alert
          confirm={(confirmation) => {
            if (confirmation && userToDelete !== null) {
              deleteUser(userToDelete.id);
              setUserToDelete(null);
            } else {
              setUserToDelete(null);
            }
          }}
          message={
            <div>
              Are you sure you want to delete the user named {userToDelete.firstName} {userToDelete.lastName}?
            </div>
          }
          isOpen={userToDelete !== null}
          isConfirm
        />
      ) : null}

      <Row>
        <Col xs='12'>
          <div className={classes['create-new-user']}>
            <div onClick={() => setShowCreateUser(true)}>
              <FaPlus /> Create New User
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className={classes['users-box']}>
            <div className={classes['headers']}>
              <div>Name</div>
              <div>User Type</div>
              <div>Email</div>
              <div>Phone</div>
              <div>Client</div>
              <div>Activation Code</div>
              <div></div>
            </div>

            <div className={isLoading ? classes['loader-container'] : classes['users-list']}>
              {isLoading ? (
                <Loader size='large' />
              ) : (
                renderUsers()
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Users;