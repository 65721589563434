import React, { useEffect, useState } from 'react';
import styles from './WindguruWidget.module.scss';
import Loader from '../../UI/Loader/Loader';

declare global {
  interface Window {
    WgWidget?: any;
  }
}

interface WindguruWidgetProps {
  stationId: string;
  widgetId: string;
  additionalParams?: Record<string, string | number | boolean>;
  title?: string;
}

const WindguruWidget: React.FC<WindguruWidgetProps> = ({ stationId, widgetId, additionalParams = {}, title = 'Wind Forecast' }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const widgetElement = document.getElementById(widgetId);
    if (widgetElement) {
      widgetElement.innerHTML = '';
    }
    const existingScript = document.getElementById(`windguru-script-${widgetId}`);
    if (existingScript) {
      existingScript.remove();
    }

    const loadWidget = () => {
      const defaultParams = {
        s: stationId,
        m: "100",
        uid: widgetId,
        wj: "kmh",
        tj: "c",
        waj: "m",
        tij: "m",
        odh: "0",
        doh: "24",
        fhours: "168",
        hrsm: "1",
        vt: "forecasts",
        lng: "en",
        idbs: "1",
        p: "WINDSPD,GUST,SMER,TMPE,TCDC,APCP1s"
      };

      const mergedParams = { ...defaultParams, ...additionalParams };

      const params = Object.entries(mergedParams).map(([key, value]) => `${key}=${value}`);

      const script = document.createElement('script');
      script.id = `windguru-script-${widgetId}`;
      script.src = "https://www.windguru.cz/js/widget.php?" + params.join("&");
      script.async = true;
      script.onload = () => setIsLoading(false);
      script.onerror = (error) => console.error('Windguru widget failed to load:', error);

      document.body.appendChild(script);
    };

    if (typeof window !== 'undefined') {
      // Only create one instance of the widget
      loadWidget();
    }

    return () => {
      // Clean up on unmount
      const script = document.getElementById(`windguru-script-${widgetId}`);
      if (script) {
        script.remove();
      }

      if (typeof window !== 'undefined') {
        const widgetElement = document.getElementById(widgetId);
        if (widgetElement) {
          widgetElement.innerHTML = '';
        }
      }
    };
  }, [stationId, widgetId, JSON.stringify(additionalParams)]);

  return (
    <div className={styles.widgetContainer}>
      <div className={styles.widgetHeader}>
        <h6>{title}</h6>
      </div>
      {isLoading && <div className="flex justify-center">{<Loader size="large" />}</div>}
      <div id={widgetId} className={styles.widgetContent} />
    </div>
  );
};

export default WindguruWidget;
