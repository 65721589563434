import { AxiosResponse } from 'axios';
import instance from './instance';
import { store } from '../../store/store';
import { SET_IS_LOGGED_IN, SET_USER } from '../../store/constants/auth';

const get = (
  path: string,
  params?: Record<string, any>,
  successCallback?: (response: AxiosResponse<any>) => void,
  errorCallback?: (error: string) => void
): void => {

  instance.defaults.withCredentials = true;

  instance
    .get(path, { params: params })
    .then((response) => {
      if (successCallback) successCallback(response);
    })
    .catch((error) => {
      let errorMessage = error.message;

      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      if (error.response && error.response.status === 401) {
        store.dispatch({
          type: SET_IS_LOGGED_IN,
          isLoggedIn: false
        });

        store.dispatch({
          type: SET_USER,
          user: null
        });
      }

      if (errorCallback) {
        errorCallback(errorMessage);
      }
    });
};

export default get;
