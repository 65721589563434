import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../../UI/Button/Button';
import classes from './LoadForm.module.scss'
import Input from '../../UI/Input/Input';
import checkValidity from '../../../helpers/checkValidity';
import get from '../../../services/axios/get';
import DatePicker from '../../UI/DatePicker/DatePicker';
import { useAppDispatch } from '../../../store/hooks';
import { customAlert } from '../../../store/actions/alert';
import Load from '../../../models/load';
import Loader from '../../UI/Loader/Loader';
import Driver from '../../../models/driver';
import User from '../../../models/user';

interface CreateNewLoadProps {
  close: () => void;
  createLoad: (load: Load, onError: () => void) => void;
  load: null
}

interface EditLoadProps {
  close: () => void;
  putLoad: (load: Load, loadId: string, onError: () => void) => void;
  load: Load
}

type LoadFormProps = CreateNewLoadProps | EditLoadProps;

export interface FormItem<T = any> {
  value: T;
  isValid: boolean;
  isTouched: boolean;
  date?: Date;
}

export interface Form {
  [key: string]: FormItem;
}

export interface Entity {
  alias: string;
  id: string;
  isDischarge?: boolean;
}

export interface EntityState<T> {
  data: T[];
  isLoading: boolean;
}

const LoadForm: React.FC<LoadFormProps> = (props: LoadFormProps) => {
  const initialForm: Form = {
    client: { value: props.load?.client ?? '', isValid: false, isTouched: false },
    clientId: { value: props.load?.clientId ?? '', isValid: false, isTouched: false },
    emptyDepot: { value: props.load?.emptyDepot ?? '', isValid: false, isTouched: false },
    temptaleType: { value: props.load?.temptaleType ?? '', isValid: false, isTouched: false },
    port: { value: props.load?.port ?? '', isValid: false, isTouched: false },
    temptaleSupplier: { value: props.load?.temptaleSupplier ?? '', isValid: false, isTouched: false },
    loadPoint1: { value: props.load?.loadPoint1 ?? '', isValid: false, isTouched: false },
    loadPoint2: { value: props.load?.loadPoint2 ?? '', isValid: false, isTouched: false },
    dischargePort: { value: props.load?.dischargePort ?? '', isValid: false, isTouched: false },
    isGenset: { value: props.load?.isGenset === true ? 'Yes' : 'No', isValid: false, isTouched: false },
    vessel: { value: props.load?.vessel ?? '', isValid: false, isTouched: false },
    commodity: { value: props.load?.commodity ?? '', isValid: false, isTouched: false },
    tempCode: { value: props.load?.tempCode ?? '', isValid: false, isTouched: false },
    shippingLine: { value: props.load?.shippingLine ?? '', isValid: false, isTouched: false },
    weighMethod: { value: props.load?.weighMethod ?? '', isValid: false, isTouched: false },
    ventType: { value: props.load?.ventType ?? '', isValid: false, isTouched: false },
    loadDate: { value: props.load?.loadDate ?? '', date: props.load?.loadDate != null ? new Date(props.load?.loadDate) : undefined, isValid: false, isTouched: false },
    containerNumber: { value: props.load?.containerNumber ?? '', isValid: false, isTouched: false },
    voyage: { value: props.load?.voyage ?? '', isValid: false, isTouched: false },
    sealNumber: { value: props.load?.sealNumber ?? '', isValid: false, isTouched: false },
    exporterRef: { value: props.load?.exporterRef ?? '', isValid: false, isTouched: false },
    bookingRef: { value: props.load?.bookingRef ?? '', isValid: false, isTouched: false },
    clientRef: { value: props.load?.clientRef ?? '', isValid: false, isTouched: false },
    truckReg: { value: props.load?.truckReg ?? '', isValid: false, isTouched: false },
    additionalInfo: { value: props.load?.additionalInfo ?? '', isValid: false, isTouched: false },
    tempSetting: { value: props.load?.tempSetting ?? '', isValid: false, isTouched: false },
    loadTime: { value: props.load?.loadTime ?? '', isValid: false, isTouched: false },
    bookingTime: { value: props.load?.bookingTime ?? '', isValid: false, isTouched: false },
    container: { value: props.load?.container ?? '', isValid: false, isTouched: false },
    status: { value: props.load?.status ?? '', isValid: false, isTouched: false },
    temptaleNumber: { value: props.load?.temptaleNumber ?? '', isValid: false, isTouched: false },
    driverName: { value: props.load?.driver?.alias ?? '', isValid: false, isTouched: false },
    driverPhoneNumber: { value: props.load?.driver?.phoneNumber ?? '', isValid: false, isTouched: false },
    driverId: { value: props.load?.driver?.id ?? '', isValid: false, isTouched: false },
    handlerName: {
      value:
        props.load?.handler?.alias ?? (
          props.load?.handler ? (props.load.handler.firstName + ' ' + props.load.handler.lastName) : ''
        ), isValid: false, isTouched: false
    }
  }

  const isMounted = useRef(true);
  const [formValues, setFormValues] = useState(initialForm)
  const dispatch = useAppDispatch();

  let defaultLoadDate = new Date();
  let maxLoadDate = new Date();
  let minLoadDate = new Date();

  defaultLoadDate.setMonth(defaultLoadDate.getMonth());
  maxLoadDate.setMonth(maxLoadDate.getMonth() + 24);
  minLoadDate.setMonth(minLoadDate.getMonth() - 6);

  const [isLoading, setIsLoading] = useState(false);

  const [clients, setClients] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [emptyDepots, setEmptyDepots] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [temptaleTypes, setTemptaleTypes] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [ports, setPorts] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [temptaleSuppliers, setTemptaleSuppliers] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [loadPoints, setLoadPoints] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [dischargePorts, setDischargePorts] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [vessels, setVessels] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [commodities, setCommodities] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [tempCodes, setTempCodes] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [shippingLines, setShippingLines] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [weighMethods, setWeighMethods] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [ventTypes, setVentTypes] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [tempSettings, setTempSettings] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [containers, setContainers] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [clientRefExists, setClientRefExists] = useState<boolean>(false);
  const [drivers, setDrivers] = useState<EntityState<Driver>>({ data: [], isLoading: true });
  const [statuses, setStatuses] = useState<EntityState<Entity>>({ data: [], isLoading: true });
  const [handlers, setHandlers] = useState<EntityState<User>>({ data: [], isLoading: false });
  const [handler, setHandler] = useState<User | null>(null);

  const parseForm = (): Load | null => {
    if (formValues.client.value.trim() === '' || formValues.client.value == null) {
      dispatch(customAlert(true, 'Please select a client'));
      return null;
    }

    const form: Partial<Load> = {};

    Object.keys(formValues).forEach((key) => {
      const loadKey = key as keyof Load;
      let value = formValues[loadKey].value;

      if ((typeof value) === 'string') {
        value = value.trim();
      }

      if (loadKey === 'isGenset') {
        form[loadKey] = formValues.isGenset.value.trim().toLowerCase() === 'yes'
      } else if (loadKey === 'preAdvised') {
        form[loadKey] = formValues.preAdvised.value.trim().toLowerCase() === 'yes'
      } else if (loadKey === 'hasBookingPhoto') {
        form[loadKey] = false;
      }

      if (
        key != null &&
        key !== 'loadStatus' &&
        key !== 'charges' &&
        key !== 'handlerName' &&
        key !== 'driverName' &&
        key !== 'driverPhoneNumber' &&
        key !== 'driverId' &&
        key !== 'isGenset' &&
        key !== 'preAdvised' &&
        key !== 'hasBookingPhoto'
      ) {
        form[loadKey] = value;
      }
    });

    if (
      formValues.driverName.value != null &&
      formValues.driverName.value !== ''
    ) {
      form['driver'] = {
        id: formValues.driverId.value,
        alias: formValues.driverName.value
      }

      if (formValues.driverPhoneNumber.value !== '') {
        form.driver.phoneNumber = formValues.driverPhoneNumber.value
      }
    } else {
      form['driver'] = null
    }

    if (
      formValues.handlerName.value !== '' &&
      formValues.handlerName.value != null &&
      handler != null
    ) {
      form['handler'] = handler
    } else if (props.load?.handler != null) {
      form['handler'] = props.load.handler
    } else {
      form['handler'] = null
    }

    if (props.load?.loadStatus === 'plugged') {
      form['loadStatus'] = form['driver'] != null ? 'assigned' : 'plugged'
    } else if (props.load?.loadStatus !== 'completed') {
      form['loadStatus'] = form['driver'] != null ? 'assigned' : 'unassigned'
    } else {
      form['loadStatus'] = 'completed'
    }

    form.hasBookingPhoto = props.load?.hasBookingPhoto ?? false

    return form as Load;
  };

  const createLoad = () => {
    const load: Load | null = parseForm()

    if (load != null && props.load == null) {
      setIsLoading(true);

      props.createLoad(load, () => setIsLoading(false));
    } else if (load != null && props.load != null) {
      setIsLoading(true);

      props.putLoad(
        { ...props.load, ...load }, props.load.id ?? '',
        () => setIsLoading(false));
    }
  }

  const checkIfClientRefExists = useCallback(
    (clientRef: string) => {
      get(
        '/load?clientRef=' + clientRef,
        {},
        (res) => {
          if (isMounted) {
            if (res.data.length > 0 && res.data[0].clientRef !== props.load?.clientRef) {
              dispatch(customAlert(true, `A load with client ref ${clientRef} already exists`))
              setClientRefExists(true);
            } else {
              setClientRefExists(false);
            }
          }
        },
        () => { });
    },
    []
  );

  const getTempSettings = useCallback(
    () => {
      get(
        '/temp-setting',
        {},
        (res) => {
          if (isMounted) {
            setTempSettings({ data: res.data, isLoading: false });
          }
        },
        () => { });
    },
    []
  );

  const getClients = useCallback(
    () => {
      get(
        '/client',
        {},
        (res) => {
          if (isMounted) {
            setClients({ data: res.data, isLoading: false });
          }
        },
        () => { });
    },
    []
  );

  const getHandlers = useCallback(
    (clientId: string) => {
      setHandlers({ data: [], isLoading: true });

      get(
        '/user',
        {
          userType: 'client',
          clientId: clientId
        },
        (res) => {
          if (isMounted) {
            setHandlers({ data: res.data, isLoading: false });
          }
        },
        () => { });
    },
    []
  );

  const getEmptyDepots = useCallback(
    () => {
      get(
        '/depot',
        {},
        (res) => {
          if (isMounted) {
            setEmptyDepots({ data: res.data, isLoading: false });
          }
        },
        () => { });
    },
    []
  );

  const getTemptaleTypes = useCallback(
    () => {
      get(
        '/temp-tale-type',
        {},
        (res) => {
          if (isMounted) {
            setTemptaleTypes({ data: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getPorts = useCallback(
    () => {
      get(
        '/port',
        {},
        (res) => {
          if (isMounted) {
            const dischargePorts: Entity[] = [];
            const ports: Entity[] = [];

            res.data.forEach((port: Entity) => {
              if (port.isDischarge) {
                dischargePorts.push(port);
              } else {
                ports.push(port);
              }
            })
            setPorts({ data: ports, isLoading: false });
            setDischargePorts({ data: dischargePorts, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getTemptaleSuppliers = useCallback(
    () => {
      get(
        '/temp-tale-supplier',
        {},
        (res) => {
          if (isMounted) {
            setTemptaleSuppliers({ data: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getLoadPoints = useCallback(
    () => {
      get(
        '/load-point',
        {},
        (res) => {
          if (isMounted) {
            setLoadPoints({ data: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getVessels = useCallback(
    () => {
      get(
        '/vessel',
        {},
        (res) => {
          if (isMounted) {
            setVessels({ data: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getCommodities = useCallback(
    () => {
      get(
        '/commodity',
        {},
        (res) => {
          if (isMounted) {
            setCommodities({ data: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getTempCodes = useCallback(
    () => {
      get(
        '/temp-code',
        {},
        (res) => {
          if (isMounted) {
            setTempCodes({ data: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getShippingLines = useCallback(
    () => {
      get(
        '/shipping-line',
        {},
        (res) => {
          if (isMounted) {
            setShippingLines({ data: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getWeighMethods = useCallback(
    () => {
      get(
        '/weigh-method',
        {},
        (res) => {
          if (isMounted) {
            setWeighMethods({ data: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getVents = useCallback(
    () => {
      get(
        '/vent-type',
        {},
        (res) => {
          if (isMounted) {
            setVentTypes({ data: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getContainers = useCallback(
    () => {
      get(
        '/container',
        {},
        (res) => {
          if (isMounted) {
            setContainers({ data: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getDrivers = useCallback(
    () => {
      get(
        '/driver',
        {},
        (res) => {
          if (isMounted) {
            setDrivers({ data: res.data, isLoading: false });
          }
        },
        () => { });
    },
    []
  );

  const getStatuses = useCallback(
    () => {
      get(
        '/status',
        {},
        (res) => {
          if (isMounted) {
            setStatuses({ data: res.data, isLoading: false });
          }
        },
        () => { });
    },
    []
  );

  const updateForm = (value: string, identifier: string, date?: Date) => {
    if (identifier === 'clientRef') {
      setClientRefExists(false)

      if (value.length > 4) {
        checkIfClientRefExists(value)
      }
    }

    setFormValues(prevState => {
      let newForm: Form = { ...prevState };
      let newItem: FormItem = { ...newForm[identifier] }

      if (typeof value === 'string' && value.length >= 1) {
        newItem.value = value.trim();
      } else {
        newItem.value = value;
      }

      newItem.isTouched = true;

      if (typeof value === 'string') {
        newItem.isValid = checkValidity(value, {
          required: true,
          minLength: 2,
          maxLength: 40,
          isEmail: identifier === 'email' ? true : false
        });
      } else {
        newItem.isValid = true
      }

      if (date != null) {
        newItem.date = date;
      }

      newForm[identifier] = newItem;

      return newForm;
    })
  };

  useEffect(() => {
    getClients();
    getEmptyDepots();
    getTemptaleTypes();
    getPorts();
    getTemptaleSuppliers();
    getLoadPoints();
    getVessels();
    getCommodities();
    getTempCodes();
    getShippingLines();
    getWeighMethods();
    getVents();
    getTempSettings();
    getContainers();

    if (props.load != null) {
      getDrivers();
      getStatuses();
    }

    return () => {
      isMounted.current = false;
    }
  }, [
    getClients,
    getEmptyDepots,
    getTemptaleTypes,
    getPorts,
    getTemptaleSuppliers,
    getLoadPoints,
    getVessels,
    getCommodities,
    getTempCodes,
    getShippingLines,
    getWeighMethods,
    getVents,
    getTempSettings,
    getContainers,
    getDrivers,
    getStatuses,
    props.load
  ])

  useEffect(() => {
    if (formValues['clientId'].value != null) {
      getHandlers(formValues['clientId'].value);
    }
  }, [
    formValues,
    getHandlers
  ])

  return (
    <div className={classes['create-new-load']}>
      <h6>{props.load ? 'Edit Load' : 'Create New Load'}</h6>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          createLoad();
        }}
      >
        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Client',
              options: [

                ...clients.data.map(
                  client => ({ value: client, displayValue: client.alias }))
              ]
            }}
            isLoading={clients.isLoading}
            value={formValues.client.value}
            invalid={!formValues.client.isValid}
            shouldValidate
            touched={formValues.client.isTouched}
            change={(e) => {
              updateForm(e.target.value.alias, 'client')
              updateForm(e.target.value.id, 'clientId')
            }}
            id='client-input'
            label='Client'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Handler',
              options: [
                ...handlers.data.map(
                  handler => ({
                    value: {
                      ...handler
                    }, displayValue: handler.alias ?? (handler.firstName + ' ' + handler.lastName)
                  })),
                  {
                    value: {
                      id: 'ALL',
                      firstName: 'ALL',
                      lastName: ''
                    }, displayValue: 'ALL'
                  }
              ]
            }}
            value={formValues.handlerName.value}
            change={(e) => {
              updateForm(e.target.value.firstName + ' ' + e.target.value.lastName, 'handlerName')
              setHandler(e.target.value)
            }}
            id='handler-input'
            label='Handler'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Load Point 1',
              options: [

                ...loadPoints.data.map(
                  point => ({ value: point.alias, displayValue: point.alias })
                )
              ]
            }}
            isLoading={loadPoints.isLoading}
            value={formValues.loadPoint1.value}
            invalid={!formValues.loadPoint1.isValid}
            shouldValidate
            touched={formValues.loadPoint1.isTouched}
            change={(e) => updateForm(e.target.value, 'loadPoint1')}
            id='loadPoint-input'
            label='Load Point 1'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Load Point 2',
              options: [

                ...loadPoints.data.map(
                  point => ({ value: point.alias, displayValue: point.alias })
                )
              ]
            }}
            isLoading={loadPoints.isLoading}
            value={formValues.loadPoint2.value}
            invalid={!formValues.loadPoint2.isValid}
            shouldValidate
            touched={formValues.loadPoint2.isTouched}
            change={(e) => updateForm(e.target.value, 'loadPoint2')}
            id='loadPoint-input'
            label='Load Point 2'
            inputStyle='main'
          />
        </div>

        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Shipping Line',
              options: [

                ...shippingLines.data.map(
                  line => ({ value: line.alias, displayValue: line.alias })
                )
              ]
            }}
            isLoading={shippingLines.isLoading}
            value={formValues.shippingLine.value}
            invalid={!formValues.shippingLine.isValid}
            shouldValidate
            touched={formValues.shippingLine.isTouched}
            change={(e) => updateForm(e.target.value, 'shippingLine')}
            id='shippingLine-input'
            label='Shipping Line'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Vessel',
              options: [
                ...vessels.data.map(
                  vessel => ({ value: vessel.alias, displayValue: vessel.alias })
                )
              ]
            }}
            isLoading={vessels.isLoading}
            value={formValues.vessel.value}
            invalid={!formValues.vessel.isValid}
            shouldValidate
            touched={formValues.vessel.isTouched}
            change={(e) => updateForm(e.target.value, 'vessel')}
            id='vessel-input'
            label='Vessel'
            inputStyle='main'
          />

          <Input
            elementType={'text'}
            elementConfig={{
              type: 'text',
              placeholder: '',
            }}
            value={formValues.voyage.value}
            invalid={!formValues.voyage.isValid}
            shouldValidate
            touched={formValues.voyage.isTouched}
            change={(e) => updateForm(e.target.value, 'voyage')}
            id='voyage-input'
            label='Voyage'
            inputStyle='main'
          />

        </div>

        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Port',
              options: [

                ...ports.data.map(
                  port => ({ value: port.alias, displayValue: port.alias })
                )
              ]
            }}
            isLoading={ports.isLoading}
            value={formValues.port.value}
            invalid={!formValues.port.isValid}
            shouldValidate
            touched={formValues.port.isTouched}
            change={(e) => updateForm(e.target.value, 'port')}
            id='port-input'
            label='Port of Load'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Port of Discharge',
              options: [
                ...dischargePorts.data.map(
                  port => ({ value: port.alias, displayValue: port.alias })
                )
              ]
            }}
            isLoading={dischargePorts.isLoading}
            value={formValues.dischargePort.value}
            invalid={!formValues.dischargePort.isValid}
            shouldValidate
            touched={formValues.dischargePort.isTouched}
            change={(e) => updateForm(e.target.value, 'dischargePort')}
            id='dischargePort-input'
            label='Port of Discharge'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Empty Depot',
              options: [

                ...emptyDepots.data.map(
                  emptyDepot => ({ value: emptyDepot.alias, displayValue: emptyDepot.alias }))
              ]
            }}
            isLoading={emptyDepots.isLoading}
            value={formValues.emptyDepot.value}
            invalid={!formValues.emptyDepot.isValid}
            shouldValidate
            touched={formValues.emptyDepot.isTouched}
            change={(e) => updateForm(e.target.value, 'emptyDepot')}
            id='emptyDepot-input'
            label='Empty Depot'
            inputStyle='main'
          />
        </div>

        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Temp Code',
              options: [

                ...tempCodes.data.map(
                  code => ({ value: code.alias, displayValue: code.alias })
                )
              ]
            }}
            isLoading={tempCodes.isLoading}
            value={formValues.tempCode.value}
            invalid={!formValues.tempCode.isValid}
            shouldValidate
            touched={formValues.tempCode.isTouched}
            change={(e) => updateForm(e.target.value, 'tempCode')}
            id='tempCode-input'
            label='Temp Code'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Temp Setting',
              options: [
                ...tempSettings.data.map(
                  tempSetting => ({ value: tempSetting.alias, displayValue: tempSetting.alias }))
              ]
            }}
            isLoading={tempSettings.isLoading}
            value={formValues.tempSetting.value}
            invalid={!formValues.tempSetting.isValid}
            shouldValidate
            touched={formValues.tempSetting.isTouched}
            change={(e) => updateForm(e.target.value, 'tempSetting')}
            id='tempSetting-input'
            label='Temp Setting'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Vent',
              options: [

                ...ventTypes.data.map(
                  vent => ({ value: vent.alias, displayValue: vent.alias })
                )
              ]
            }}
            isLoading={ventTypes.isLoading}
            value={formValues.ventType.value}
            invalid={!formValues.ventType.isValid}
            shouldValidate
            touched={formValues.ventType.isTouched}
            change={(e) => updateForm(e.target.value, 'ventType')}
            id='vent-input'
            label='Vent'
            inputStyle='main'
          />
        </div>

        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              displayValue: 'Temptale Supplier',
              options: [

                ...temptaleSuppliers.data.map(
                  supplier => ({ value: supplier.alias, displayValue: supplier.alias })
                )
              ]
            }}
            isLoading={temptaleSuppliers.isLoading}
            value={formValues.temptaleSupplier.value}
            invalid={!formValues.temptaleSupplier.isValid}
            shouldValidate
            touched={formValues.temptaleSupplier.isTouched}
            change={(e) => updateForm(e.target.value, 'temptaleSupplier')}
            id='temptaleSupplier-input'
            label='Temptale Supplier'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Temptale Type',
              options: [
                ...temptaleTypes.data.map(
                  type => ({ value: type.alias, displayValue: type.alias }))
              ]
            }}
            isLoading={temptaleTypes.isLoading}
            value={formValues.temptaleType.value}
            invalid={!formValues.temptaleType.isValid}
            shouldValidate
            touched={formValues.temptaleType.isTouched}
            change={(e) => updateForm(e.target.value, 'temptaleType')}
            id='temptaleType-input'
            label='Temptale Type'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Commodity',
              options: [

                ...commodities.data.map(
                  commodity => ({ value: commodity.alias, displayValue: commodity.alias })
                )
              ]
            }}
            isLoading={commodities.isLoading}
            value={formValues.commodity.value}
            invalid={!formValues.commodity.isValid}
            shouldValidate
            touched={formValues.commodity.isTouched}
            change={(e) => updateForm(e.target.value, 'commodity')}
            id='commodity-input'
            label='Commodity'
            inputStyle='main'
          />
        </div>

        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'text'}
            elementConfig={{
              type: 'text',
              placeholder: '',
            }}
            value={formValues.clientRef.value}
            invalid={!formValues.clientRef.isValid || clientRefExists}
            shouldValidate
            touched={formValues.clientRef.isTouched || clientRefExists}
            change={(e) => updateForm(e.target.value, 'clientRef')}
            id='clientRef-input'
            label='Client Ref.'
            inputStyle='main'
          />

          <Input
            elementType={'text'}
            elementConfig={{
              type: 'text',
              placeholder: '',
            }}
            value={formValues.bookingRef.value}
            invalid={!formValues.bookingRef.isValid}
            shouldValidate
            touched={formValues.bookingRef.isTouched}
            change={(e) => updateForm(e.target.value, 'bookingRef')}
            id='bookingRef-input'
            label='Booking Ref.'
            inputStyle='main'
          />

          <Input
            elementType={'text'}
            elementConfig={{
              type: 'text',
              placeholder: '',
            }}
            value={formValues.exporterRef.value}
            invalid={!formValues.exporterRef.isValid}
            shouldValidate
            touched={formValues.exporterRef.isTouched}
            change={(e) => updateForm(e.target.value, 'exporterRef')}
            id='exporterRef-input'
            label='Exporter Ref.'
            inputStyle='main'
          />
        </div>

        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Weigh Method',
              options: [

                ...weighMethods.data.map(
                  method => ({ value: method.alias, displayValue: method.alias })
                )
              ]
            }}
            isLoading={weighMethods.isLoading}
            value={formValues.weighMethod.value}
            invalid={!formValues.weighMethod.isValid}
            shouldValidate
            touched={formValues.weighMethod.isTouched}
            change={(e) => updateForm(e.target.value, 'weighMethod')}
            id='weighMethod-input'
            label='Weigh Method'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Container Info',
              options: [
                ...containers.data.map(
                  container => ({ value: container.alias, displayValue: container.alias }))
              ]
            }}
            isLoading={containers.isLoading}
            value={formValues.container.value}
            invalid={!formValues.container.isValid}
            shouldValidate
            touched={formValues.container.isTouched}
            change={(e) => updateForm(e.target.value, 'container')}
            id='container-input'
            label='Container Info'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Genset',
              options: [

                { displayValue: 'Yes', value: 'Yes', isPlaceholder: false },
                { displayValue: 'No', value: 'No', isPlaceholder: false }
              ]
            }}
            value={formValues.isGenset.value}
            invalid={!formValues.isGenset.isValid}
            shouldValidate
            touched={formValues.isGenset.isTouched}
            change={(e) => updateForm(e.target.value, 'isGenset')}
            id='genset-input'
            label='Genset'
            inputStyle='main'
          />
        </div>

        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'text'}
            elementConfig={{
              type: 'text',
              placeholder: '',
            }}
            value={formValues.sealNumber.value}
            invalid={!formValues.sealNumber.isValid}
            shouldValidate
            touched={formValues.sealNumber.isTouched}
            change={(e) => updateForm(e.target.value, 'sealNumber')}
            id='seal-input'
            label='Seal No.'
            inputStyle='main'
          />

          <Input
            elementType={'text'}
            elementConfig={{
              type: 'text',
              placeholder: '',
            }}
            value={formValues.containerNumber.value}
            invalid={!formValues.containerNumber.isValid}
            shouldValidate
            touched={formValues.containerNumber.isTouched}
            change={(e) => updateForm(e.target.value, 'containerNumber')}
            id='containerNumber-input'
            label='Container No.'
            inputStyle='main'
          />

          <Input
            elementType={'text'}
            elementConfig={{
              type: 'text',
              placeholder: '',
            }}
            value={formValues.temptaleNumber.value}
            invalid={!formValues.temptaleNumber.isValid}
            shouldValidate
            touched={formValues.temptaleNumber.isTouched}
            change={(e) => updateForm(e.target.value, 'temptaleNumber')}
            id='temptaleNumber-input'
            label='Temptale Number.'
            inputStyle='main'
          />
        </div>

        {props.load != null && (
          <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
            <Input
              elementType={'select'}
              allowEmpty={true}
              elementConfig={{
                type: 'select',
                placeholder: 'Status',
                options: [
                  ...statuses.data.map(
                    status => ({ value: status.alias, displayValue: status.alias }))
                ]
              }}
              isLoading={statuses.isLoading}
              value={formValues.status.value}
              invalid={!formValues.status.isValid}
              shouldValidate
              touched={formValues.status.isTouched}
              change={(e) => updateForm(e.target.value, 'status')}
              id='status-input'
              label='Status'
              inputStyle='main'
            />

            <Input
              elementType={'select'}
              allowEmpty={true}
              elementConfig={{
                type: 'select',
                placeholder: 'Driver',
                options: [
                  ...drivers.data.map(
                    driver => ({
                      value: {
                        alias: driver.alias,
                        phoneNumber: driver.phoneNumber,
                        id: driver.id
                      }, displayValue: driver.alias
                    }))
                ]
              }}
              value={formValues.driverName.value}
              change={(e) => {
                updateForm(e.target.value.alias, 'driverName')
                updateForm(e.target.value.phoneNumber, 'driverPhoneNumber')
                updateForm(e.target.value.id, 'driverId')
              }}
              id='driver-input'
              label='Driver'
              inputStyle='main'
            />

            <Input
              elementType={'text'}
              elementConfig={{
                type: 'text',
                placeholder: '',
              }}
              value={formValues.truckReg.value}
              invalid={!formValues.truckReg.isValid}
              shouldValidate
              touched={formValues.truckReg.isTouched}
              change={(e) => updateForm(e.target.value, 'truckReg')}
              id='truckReg-input'
              label='Truck Reg.'
              inputStyle='main'
            />
          </div>
        )}

        <div>
          <Input
            elementType={'textarea'}
            elementConfig={{
              type: 'textarea',
              placeholder: '',
            }}
            value={formValues.additionalInfo.value}
            invalid={!formValues.additionalInfo.isValid}
            shouldValidate
            touched={formValues.additionalInfo.isTouched}
            change={(e) => updateForm(e.target.value, 'additionalInfo')}
            id='additionalInfo-input'
            label='Additional Info.'
            inputStyle='main'
          />
        </div>

        <div className='d-flex'>
          <Input
            id="loadTime"
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Load Time',
              options: Array.from({ length: 24 }, (_, i) => ({
                value: i.toString().padStart(2, '0') + ":00",
                displayValue: i.toString().padStart(2, '0') + ":00"
              }))
            }}
            value={formValues.loadTime.value}
            invalid={!formValues.loadTime.isValid}
            shouldValidate
            touched={formValues.loadTime.isTouched}
            change={(e) => updateForm(e.target.value, 'loadTime')}
            label="Load Time"
          />

          <div className='px-3'></div>

          <Input
            id="bookingTime"
            elementType={'select'}
            elementConfig={{
              type: 'select',
              placeholder: 'Booking Time',
              options: Array.from({ length: 24 }, (_, i) => ({
                value: i.toString().padStart(2, '0') + ":00",
                displayValue: i.toString().padStart(2, '0') + ":00"
              }))
            }}
            value={formValues.bookingTime.value}
            invalid={!formValues.bookingTime.isValid}
            shouldValidate
            touched={formValues.bookingTime.isTouched}
            change={(e) => updateForm(e.target.value, 'bookingTime')}
            label="Booking Time"
          />
        </div>

        <div className='pt-3'>
          <div
            className={
              !formValues.loadDate.isValid &&
                formValues.loadDate.isTouched
                ? [classes['load-date'], classes['invalid-load-date']].join(' ')
                : classes['load-date']
            }
          >
            <span>Load Date:</span>
            <div className='mt-2 text-center'>
              <br />
              <span>
                {formValues.loadDate.date
                  ? formValues.loadDate.date.toDateString()
                  : 'No date selected'}
              </span>
            </div>

            <DatePicker
              defaultDate={defaultLoadDate}
              maxDate={maxLoadDate}
              minDate={minLoadDate}
              callback={(date, isValid) => {
                if (isValid && date !== null) {
                  updateForm(date?.toISOString(), 'loadDate', date);
                }
              }}
              invalidMessage='Invalid date'
              autoConfirm
            />
          </div>
        </div>

        <div className={[classes.buttons, 'pt-3'].join(' ')}>
          <div className='pointer no-select' onClick={props.close}>Cancel</div>

          {isLoading ? <Loader
            size='small'
          /> : (
            <Button
              type='submit'
              buttonStyle='main'
              text={props.load ? 'Update Load' : 'Create Load'}
            />
          )}
        </div>
      </form >
    </div >
  );
};

export default LoadForm;
