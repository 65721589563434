import React, { useCallback, useEffect, useRef, useState } from 'react';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface ClientsProps { };

const Clients: React.FC<ClientsProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewClient = (client: { alias: string }) => {
    post(
      '/client',
      { alias: client.alias },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getClients();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteClient = (clientId: string) => {
    del(
      `/client/${clientId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getClients();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getClients = useCallback(
    () => {
      get(
        '/client',
        {},
        (res) => {
          if (isMounted) {
            setClients(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getClients();

    return () => {
      isMounted.current = false;
    }
  }, [getClients])

  return (
    <>
      <EntityBox
        entityName='client'
        header='Clients'
        isLoading={isLoading}
        entities={clients}
        isBroken={isBroken}
        delete={deleteClient}
        createNewEntity={createNewClient}
      />
    </>

  );
};

export default Clients;