import React, { FC, useCallback, useEffect, useRef } from 'react';
import Main from '../Main/Main';
import Auth from '../Auth/Auth';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { customAlert } from '../../store/actions/alert';
import Alert from '../../components/UI/Alert/Alert';
import Loader from '../../components/UI/Loader/Loader';
import { setUser } from '../../store/actions/auth';
import get from '../../services/axios/get';
import styles from './Root.module.scss';


const Root: FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const showAlert = useAppSelector((state) => state.alert.showAlert);
  const alertMessage = useAppSelector((state) => state.alert.alertMessage);
  const isMounted = useRef(true);

  const init = useCallback(
    () => {
      get(
        '/init',
        {},
        (res) => {
          if (isMounted) {
            dispatch(setUser(res.data));
          }
        },
        () => {});
    },
    [dispatch]
  );

  useEffect(() => {
    if (isLoggedIn === true) {
      init();
    }

    return () => {
      isMounted.current = false;
    }
  }, [dispatch, isLoggedIn, init]);

  const render = () => {
    if (user != null && isLoggedIn === true) {
      return <Main />
    } else if (isLoggedIn === true) {
      return (
        <div className={styles.loaderContainer}>
          <Loader size='large' />
        </div>
      );
    } else {
      return <Auth />
    }
  }

  return (
    <div className={styles.root}>
      <Alert
        ok={() => dispatch(customAlert(false))}
        message={alertMessage}
        isOpen={showAlert}
      />
      {render()}
    </div>
  );
};

export default Root;
