import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import LoadCounts from './LoadCounts/LoadCounts';
import WindguruWidget from './WindguruWidget/WindguruWidget';

interface HomeProps { };

const Home: React.FC<HomeProps> = (props) => {
  return (
    <Container>
      <Row>
        <Col xs='12' lg='6'>
          <LoadCounts />
        </Col>

        <Col xs='12' lg='6'>
          <WindguruWidget
            stationId="51679"
            widgetId="wg_fwdg_51679_100_1740904932372"
            title='Port Elizabeth Wind Forecast'
          />
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col xs='12' lg='6'>
          <WindguruWidget
            stationId="1216014"
            widgetId="wg_fwdg_1216014_100_1740905345653"
            additionalParams={{ ai: "1" }}
            title='Cape Town Wind Forecast'
          />
        </Col>

        <Col xs='12' lg='6'>
          <WindguruWidget
            stationId="208308"
            widgetId="wg_fwdg_208308_100_1740905873765"
            title='Durban Wind Forecast'
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;