import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './LoadPoints.module.scss';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';
import { Entity } from '../EntityBox/NewEntity/NewEnity';

interface LoadPointProps { };

interface LoadPoint {
  id: string;
  alias: string;
  region: 'WC' | 'EC';
}

const LoadPoints: React.FC<LoadPointProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [loadPoints, setLoadPoint] = useState<LoadPoint[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewLoadPoint = (loadPoint: Entity) => {
    if (!loadPoint.region) {
      loadPoint.region = 'WC';
    }

    post(
      '/load-point',
      {
        alias: loadPoint.alias,
        region: loadPoint.region,
        isDischarge: false
      },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getLoadPoints();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteLoadPoint = (loadPointId: string) => {
    del(
      `/load-point/${loadPointId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getLoadPoints();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editLoadPoint = (loadPointId: string, loadPointName: string, region?: 'WC' | 'EC') => {
    patch(
      `/load-point/${loadPointId}`,
      {
        alias: loadPointName,
        ...(region && { region })
      },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getLoadPoints();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getLoadPoints = useCallback(
    () => {
      get(
        '/load-point',
        { isDischarge: false },
        (res) => {
          if (isMounted) {
            setLoadPoint(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getLoadPoints();

    return () => {
      isMounted.current = false;
    }
  }, [getLoadPoints])

  return (
    <>
      <EntityBox
        entityName='load point'
        header='Load Points'
        isLoading={isLoading}
        entities={loadPoints}
        isBroken={isBroken}
        delete={deleteLoadPoint}
        edit={editLoadPoint}
        createNewEntity={createNewLoadPoint}
        regionOptions={['WC', 'EC']}
      />
    </>

  );
};

export default LoadPoints;