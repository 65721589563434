import { AxiosResponse, AxiosRequestConfig } from 'axios';
import instance from './instance';

const patch = (
  path: string,
  params?: Record<string, any>,
  successCallback?: (response: AxiosResponse<any>) => void,
  errorCallback?: (error: string) => void,
  options?: AxiosRequestConfig
): void => {
  instance.defaults.withCredentials = true;

  instance
    .patch(path, params)
    .then((response) => {
      if (successCallback) successCallback(response);
    })
    .catch((error) => {
      let errorMessage = error.message;

      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      if (errorCallback) {
        errorCallback(errorMessage);
      }
    });
};

export default patch;
