import React from 'react';
import classes from './ChargesPdf.module.scss';
import Load, { Charge } from '../../../models/load';
import { Document, Image, PDFDownloadLink, PDFViewer, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import logo from '../../../assets/images/logo.png';
import { isMobile } from 'react-device-detect';

interface ChargesPdfProps {
  load: Load;
  close?: () => void;
};

const styles = StyleSheet.create({
  page: {
    fontSize: '12px'
  },
  outer: {
    margin: '15px',
    border: '1px solid black'
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    borderBottom: '1px solid black',
    paddingBottom: '10px'
  },
  image: {
    height: 'auto',
    width: '280px',
    marginLeft: '30px',
    marginTop: '30px'
  },
  row: {
    borderTop: '1px solid black',
    display: 'flex',
    flexDirection: 'row'
  },
  halfCell: {
    width: '25%',
    padding: '8px',
    textAlign: 'center'
  },
  quarterCell: {
    width: '12.5%',
    padding: '8px',
    textAlign: 'center'
  },
  cell: {
    width: '50%',
    padding: '8px',
    textAlign: 'center'
  },
  blockCell: {
    width: '100%',
    padding: '8px'
  },
  endBorder: {
    borderRight: '1px solid black',
  },
  heading: {
    textDecoration: 'underline'
  },
  textRow: {
    padding: '8px',
    textAlign: 'center',
    width: '100%'
  },
  label: {

  },
  value: {
    color: 'rgb(100,100,100)',
    textAlign: 'right'
  },
  notification: {
    backgroundColor: 'yellow',
    padding: '2px',
    textAlign: 'center',
    textDecoration: 'underline'
  },
  fifthCell: {
    width: '20%',
    padding: '8px',
    textAlign: 'center'
  },
  tenthCell: {
    width: '10%',
    padding: '8px',
    textAlign: 'center'
  }
});

export const ChargesDocument = (props: ChargesPdfProps) => {
  let total = 0;

  return <Document>
    <Page size='A4' style={styles.page}>
      <View style={styles.outer}>
        <View style={styles.header}>
          <Image src={logo} style={styles.image} />
        </View>

        <View style={styles.notification}>
          <Text>Additional Charges Notification</Text>
        </View>

        {/* Row 1 */}
        <View style={styles.row}>
          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.label}>Date:</Text>
          </View>

          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.value}>{new Date(props.load.loadDate ?? '').toDateString()}</Text>
          </View>
        </View>

        {/* Row 2 */}
        <View style={styles.row}>
          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.label}>Booking Ref:</Text>
          </View>

          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.value}>{props.load.bookingRef}</Text>
          </View>
        </View>

        {/* Row 3 */}
        <View style={styles.row}>
          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.label}>Client Ref:</Text>
          </View>

          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.value}>{props.load.clientRef}</Text>
          </View>
        </View>

        {/* Row 4 */}
        <View style={styles.row}>
          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.label}>Container No.:</Text>
          </View>

          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.value}>{props.load.containerNumber}</Text>
          </View>
        </View>

        {/* Row 5 */}
        <View style={styles.row}>
          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.label}></Text>
          </View>

          <View style={{ ...styles.tenthCell, ...styles.endBorder }}>
            <Text style={styles.label}>Qty</Text>
          </View>

          <View style={{ ...styles.fifthCell, ...styles.endBorder }}>
            <Text style={styles.label}>Cost</Text>
          </View>

          <View style={{ ...styles.fifthCell, ...styles.endBorder }}>
            <Text style={styles.label}>Total</Text>
          </View>
        </View>

        {JSON.parse(props.load.charges ?? '[]').map((charge: Charge, index: string) => {
          total = total + (parseFloat(charge.amount) * charge.quantity)

          return <View key={index} style={styles.row}>
            <View style={{ ...styles.cell, ...styles.endBorder }}>
              <Text style={styles.label}>{charge.description
                .replace(/([A-Z])/g, ' $1')
                .replace(/^./, str => str.toUpperCase())
                .trim()}:</Text>
            </View>

            <View style={{ ...styles.tenthCell, ...styles.endBorder }}>
              <Text style={styles.value}>{charge.quantity}</Text>
            </View>

            <View style={{ ...styles.fifthCell, ...styles.endBorder }}>
              <Text style={styles.value}>R {parseFloat(charge.amount).toFixed(0)}</Text>
            </View>

            <View style={{ ...styles.fifthCell, ...styles.endBorder }}>
              <Text style={styles.value}>R {(charge.quantity * parseFloat(charge.amount)).toFixed(0)}</Text>
            </View>
          </View>
        })}

        <View style={styles.row}>
          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.label}></Text>
          </View>

          <View style={{ ...styles.tenthCell, ...styles.endBorder }}>
          </View>

          <View style={{ ...styles.fifthCell, ...styles.endBorder }}>
            <Text style={styles.label}>SUBTOTAL:</Text>
          </View>

          <View style={{ ...styles.fifthCell, ...styles.endBorder }}>
            <Text style={styles.value}>R {total.toFixed(0)}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.label}></Text>
          </View>

          <View style={{ ...styles.tenthCell, ...styles.endBorder }}>
          </View>

          <View style={{ ...styles.fifthCell, ...styles.endBorder }}>
            <Text style={styles.label}>VAT:</Text>
          </View>

          <View style={{ ...styles.fifthCell, ...styles.endBorder }}>
            <Text style={styles.value}>R {(total * 0.15).toFixed(0)}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.label}></Text>
          </View>

          <View style={{ ...styles.tenthCell, ...styles.endBorder }}>
          </View>

          <View style={{ ...styles.fifthCell, ...styles.endBorder }}>
            <Text style={styles.label}>TOTAL:</Text>
          </View>

          <View style={{ ...styles.fifthCell, ...styles.endBorder }}>
            <Text style={styles.value}>R {(total * 1.15).toFixed(0)}</Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
}

const ChargesPdf = (props: ChargesPdfProps) => (
  <div className={isMobile ? classes['pdf-mobile-container'] : classes['pdf-container']}>
    {isMobile ? (
      <div className='p-2 text-center'>Charges.{props.load.clientRef}.pdf</div>
    ) : (
      <div className={classes['pdf']}>
        <PDFViewer width="100%" height="100%" showToolbar={false}>
          <ChargesDocument load={props.load} />
        </PDFViewer>
      </div>
    )}

    <div className={classes['buttons']}>
      <div className={classes.cancel} onClick={props.close}>Close</div>

      <div className={classes['download']}>
        <PDFDownloadLink document={<ChargesDocument load={props.load} />} fileName={`Charges.${props.load.clientRef}.pdf`}>
          {({ blob, url, loading, error }) => (loading ? '' : 'Download')}
        </PDFDownloadLink>
      </div>
    </div>
  </div>
)

export default ChargesPdf;