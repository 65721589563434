import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './DriverLoads.module.scss';
import { Col, Container, Row } from 'reactstrap';
import get from '../../../services/axios/get';
import LoadItem from './LoadItem/LoadItem';
import Loader from '../../UI/Loader/Loader';
import { useAppDispatch } from '../../../store/hooks';
import { customAlert } from '../../../store/actions/alert';
import patch from '../../../services/axios/patch';
import Status from '../../../models/status';
import Load from '../../../models/load';
import Input from '../../UI/Input/Input';
import { Document } from '../../Loads/Documents/Documents';
import { FaCheckCircle, FaCube, FaReceipt, FaStamp, FaTemperatureHigh, FaTruck, FaWeightHanging } from 'react-icons/fa';
import LoadPdf from '../../Loads/LoadPdf/LoadPdf';
import Modal from '../../../containers/Modal/Modal';
import post from '../../../services/axios/post';
import Button from '../../UI/Button/Button';

interface DriverLoadsProps {
  loadStatus: 'completed' | 'active' | 'new'
}

const documentsUploadedMapInitial: any = { // TODO: Give this a proper type
  "Seal Photo": null,
  "Temptale Photo": null,
  "VGM 1 Photo": null,
  "VGM 2 Photo": null,
  "Port Slip Photo": null,
  "Container Photo": null,
  "Booking Photo": null,
  "Driver CTO": null
}

const completedStatus = {
  alias: '*** COMPLETED ***',
  entityType: 'status',
  id: 'completed'
}

const DriverLoads: React.FC<DriverLoadsProps> = (props: DriverLoadsProps) => {
  const dispatch = useAppDispatch();
  const isMounted = useRef(true);
  const [loads, setLoads]: [Load[], any] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [statuses, setStatuses]: [
    { statuses: Status[], isLoading: boolean },
    (statuses: any) => any
  ] = useState({ statuses: [], isLoading: true });
  const [selectedLoad, setSelectedLoad] = useState<Load>();
  const [documentsUploadedMap, setDocumentsUploadedMap] = useState({ ...documentsUploadedMapInitial })
  const [showCto, setShowCto] = useState<boolean>(false)
  const fileInputRef: any = useRef(null);
  const [documentToUpload, setDocumentToUpload] = useState<string>()
  const [editingField, setEditingField] = useState<{ label: string; field: 'containerNumber' | 'sealNumber' }>()
  const [editingValue, setEditingValue] = useState<string>()
  const [viewingDocument, setViewingDocument] = useState<Document>()
  const [showETATimePicker, setShowETATimePicker] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [tempEtaTime, setTempEtaTime] = useState<string>('');

  const onClickedFileUpload = (file: File): void => {
    if (selectedLoad == null) {
      return
    }

    // @ts-expect-error
    const nameParts = documentToUpload.split('.');
    const secondPart = selectedLoad[nameParts[1] as keyof Load] != null && selectedLoad[nameParts[1] as keyof Load] !== '' ? `.${selectedLoad[nameParts[1] as keyof Load]}` : '';
    const fileNameParts = file.name.split('.')

    onFileUpload(file, `${nameParts[0]}${secondPart}.${fileNameParts[fileNameParts.length - 1]}`);
  }

  const onPhotoClick = (documentKey: keyof typeof documentsUploadedMap): void => {
    if (documentsUploadedMap[documentKey] != null) {
      setViewingDocument(documentsUploadedMap[documentKey])
    } else if (fileInputRef.current?.click != null && props.loadStatus !== 'completed' && documentKey !== 'Booking Photo') {
      setDocumentToUpload(`${String(documentKey)}.clientRef.jpeg`);
      fileInputRef.current.click()
    }
  }

  const onPdfClick = (documentKey: keyof typeof documentsUploadedMap): void => {
    if (documentsUploadedMap[documentKey] != null) {
      window.open(documentsUploadedMap[documentKey].signedUrl, '_blank');
    } else if (fileInputRef.current?.click != null && props.loadStatus !== 'completed') {
      setDocumentToUpload(`${String(documentKey)}.clientRef.pdf`);
      fileInputRef.current.click()
    }
  }

  const onFileUpload = async (file: File, documentName?: string) => {
    if (selectedLoad == null) {
      return;
    }

    get(
      '/document/upload',
      {
        documentName: documentName ?? file.name,
        loadId: selectedLoad.id
      },
      async (response) => {
        const uploadResponse = await fetch(response.data.uploadUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type
          }
        });

        if (uploadResponse.ok) {
          post(
            '/document',
            {
              documentName: documentName ?? file.name,
              loadId: selectedLoad.id
            },
            (response) => {
              if (fileInputRef.current != null) {
                fileInputRef.current.value = '';
              }

              getDocuments();
            },
            (error: any) => {
              dispatch(customAlert(true, error))
            }
          )
        } else {
          dispatch(customAlert(true, 'Upload failed'))
        }
      },
      () => { }
    )
  };

  const getDocuments = useCallback(
    () => {
      if (selectedLoad == null) {
        return
      }

      get(
        '/document',
        { loadId: selectedLoad.id },
        (res) => {
          const newDocumentsUploadedMap = { ...documentsUploadedMapInitial }
          if (isMounted) {
            res.data.forEach((doc: Document) => {
              const documentName = doc.documentName.split('.')[0] as keyof typeof documentsUploadedMapInitial
              newDocumentsUploadedMap[documentName] = doc
            })

            setDocumentsUploadedMap(newDocumentsUploadedMap)
          }
        },
        () => { }
      );
    },
    [selectedLoad]
  );

  const getStatuses = useCallback(
    () => {
      get(
        '/status',
        {},
        (res) => {
          if (isMounted) {
            setStatuses({ statuses: [completedStatus, ...res.data], isLoading: false });
          }
        },
        () => { });
    },
    []
  );

  const getLoads = useCallback(
    () => {
      setIsLoading(true);
      setLoads([]);

      get(
        `/load`,
        {
          loadStatus: props.loadStatus
        },
        (res) => {
          if (isMounted) {
            if (res.data.length === 0) {
              setSelectedLoad(undefined)
            } else if (selectedLoad == null) {
              setSelectedLoad(res.data[0])
            }

            setLoads(res.data);
            setIsLoading(false);
          }
        },
        (err) => {
          dispatch(customAlert(true, err));
        }
      );
    },
    []
  );

  const updateLoad = useCallback(
    (id: string, key: string, value: string | boolean | object | null, status?: string) => {
      const payload = {
        [key]: value
      }

      if (status !== undefined) {
        payload['status'] = status;
      }

      patch(
        `/load/${id}`,
        payload,
        () => {
          if (isMounted) {
            getLoads();
          }
        },
        (err) => {
          dispatch(customAlert(true, err));
        }
      )
    },
    [getLoads, dispatch]
  )

  const acceptLoad = useCallback(
    (id: string) => {
      patch(
        `/load/${id}`,
        {
          isActive: true
        }
        ,
        () => {
          if (isMounted) {
            getLoads();
          }
        },
        (err) => {
          dispatch(customAlert(true, err));
        }
      )
    },
    [getLoads, dispatch]
  )

  const sortByDate = (unSortedLoads: Load[]): Load[] => {
    return unSortedLoads.sort((a: Load, b: Load) => {
      const dateA: any = new Date(a.loadDate ?? '1000-01-01');
      const dateB: any = new Date(b.loadDate ?? '1000-01-01');

      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;

      if (a.client < b.client) return -1;
      if (a.client > b.client) return 1;

      return 0;
    });
  }

  const sortLoads = (unSortedLoads: Load[]): Load[] => {
    let sortedLoads = sortByDate(unSortedLoads)

    return sortedLoads
  }

  const renderLoads = () => {
    const sortedLoads = sortLoads(loads)

    const sortedLoadComponents = sortedLoads.map((load, index) => {
      const shadeClass = index % 2 === 0 ? 'even' : 'odd';
      return <LoadItem
        statuses={statuses}
        refresh={getLoads}
        key={load.id}
        shade={shadeClass}
        load={load}
        selected={selectedLoad?.id === load.id}
        select={setSelectedLoad}
      />
    });

    return sortedLoadComponents
  }

  useEffect(() => {
    getDocuments()
  }, [getDocuments, selectedLoad])

  useEffect(() => {
    getLoads();

    if (props.loadStatus === 'active') {
      getStatuses();
    }

    return () => {
      isMounted.current = false;
    }
  }, [
    getLoads,
    getStatuses,
    props.loadStatus
  ])

  return (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={(event) => {
          const file = event?.target?.files?.[0];

          if (!file) {
            return;
          }

          onClickedFileUpload(file);
        }}
      />

      {editingField != null ? (
        <Modal close={() => setEditingField(undefined)}>
          <div>
            <Input
              elementType={'text'}
              elementConfig={{
                type: 'text',
                placeholder: '',
              }}
              value={editingValue == null ? selectedLoad?.[editingField.field] ?? '' : editingValue}
              change={(e) => setEditingValue(e.target.value)}
              id='number-input'
              label={editingField.label}
              inputStyle='main'
            />

            <div className={classes['submit']}>
              <Button buttonStyle='main' type='button' text='Submit' click={() => {
                if (selectedLoad == null) return

                updateLoad(selectedLoad.id, editingField.field, editingValue ?? null)
                setEditingField(undefined)
                setEditingValue(undefined)
              }} />
            </div>
          </div>
        </Modal>
      ) : null}

      {viewingDocument != null ? (
        <Modal close={() => setViewingDocument(undefined)}>
          <div>
            <div className={classes['photo']}>
              <img src={viewingDocument.signedUrl} alt="Load Photo" width={'100%'} />
            </div>

            <div className={classes['photo-buttons']}>
              <Button buttonStyle='main' type='button' text='Close' click={() => setViewingDocument(undefined)} />

              {props.loadStatus !== 'completed' && viewingDocument.documentName.split('.')[0] !== 'Booking Photo' ? (
                <Button buttonStyle='main' type='button' text='Reupload' click={() => {
                  if (fileInputRef.current?.click != null) {
                    setDocumentToUpload(viewingDocument.documentName);
                    fileInputRef.current.click()
                  }
                }} />
              ) : null}
            </div>
          </div>
        </Modal>
      ) : null}

      {showETATimePicker === true ? (
        <Modal close={() => setShowETATimePicker(false)}>
          <div className={classes.etaTimePicker}>
            <h6>Select ETA Time</h6>
            <Input
              id="etaTime"
              elementType={'select'}
              allowEmpty={true}
              elementConfig={{
                type: 'select',
                placeholder: 'ETA Time',
                options: Array.from({ length: 24 }, (_, i) => ({
                  value: i.toString().padStart(2, '0') + ":00",
                  displayValue: i.toString().padStart(2, '0') + ":00"
                }))
              }}
              value={selectedLoad?.etaTime ?? tempEtaTime}
              change={(e) => setTempEtaTime(e.target.value)}
              inputStyle="main"
              labelHidden
            />
            <div className={classes.etaButtons}>
              <Button
                type="button"
                buttonStyle="main"
                text="Confirm"
                click={() => {
                  if (selectedLoad && selectedStatus && tempEtaTime) {
                    updateLoad(selectedLoad.id, 'etaTime', tempEtaTime, selectedStatus);
                    setShowETATimePicker(false);
                    setSelectedStatus(null);
                    setTempEtaTime('');
                  }
                }}
              />
              <Button
                type="button"
                buttonStyle="main"
                text="Cancel"
                click={() => {
                  setShowETATimePicker(false);
                  setSelectedStatus(null);
                  setTempEtaTime('');
                }}
              />
            </div>
          </div>
        </Modal>
      ) : null}

      <Container fluid>
        {
          showCto && selectedLoad != null ? (
            <Modal
              close={() => setShowCto(false)}
              style={{ width: '1000px' }}
              stacked={false}
              stretch
            >
              <LoadPdf load={selectedLoad} close={() => setShowCto(false)}></LoadPdf>
            </Modal>
          ) : null
        }

        <Row className='g-0'>
          <Col>
            <div className={classes['loads-box']}>
              <Row className={[classes.columns, 'g-0'].join(' ')}>
                <Col className={classes['sort-header']}>Date</Col>
                <Col className={[classes['equal-width-columns']].join(' ')}>Load Point 1</Col>
                <Col className={[classes['equal-width-columns']].join(' ')}>Load Point 2</Col>
                <Col className={[classes['equal-width-columns']].join(' ')}>Booking Number</Col>
              </Row>

              <div className={classes['loads-content']}>
                {isLoading ? <div className={classes['loader-container']}>
                  <Loader size='large' />
                </div> : renderLoads()}
              </div>
            </div>
          </Col>
        </Row>

        {
          selectedLoad != null ? (
            <>
              {props.loadStatus === 'active' ? (
                <>
                  <Row>
                    <Col>
                      <Input
                        elementType="select"
                        allowEmpty={true}
                        elementConfig={{
                          type: 'select',
                          placeholder: 'Load Status',
                          options: [
                            ...statuses.statuses.map(status => ({
                              value: status.alias,
                              displayValue: status.alias,
                            })),
                            { value: 'EN ROUTE TO 1st LP', displayValue: 'EN ROUTE TO 1st LP' },
                            { value: 'EN ROUTE TO 2nd LP', displayValue: 'EN ROUTE TO 2nd LP' },
                            { value: 'EN ROUTE TO PORT', displayValue: 'EN ROUTE TO PORT' }
                          ],
                        }}
                        value={selectedLoad.status ?? ''}
                        change={(e) => {
                          const statusValue = e.target.value;
                          if (
                            statusValue === 'EN ROUTE TO 1st LP' ||
                            statusValue === 'EN ROUTE TO 2nd LP' ||
                            statusValue === 'EN ROUTE TO PORT'
                          ) {
                            setSelectedStatus(statusValue);
                            setShowETATimePicker(true);
                          } else {
                            updateLoad(selectedLoad.id, 'status', statusValue);
                          }
                        }}
                        id={"status-input" + selectedLoad.id}
                        inputStyle="inline"
                        label='Load Status'
                        key={selectedLoad.id}
                      />
                    </Col>

                    <Col>
                      <Input
                        elementType="select"
                        allowEmpty={true}
                        elementConfig={{
                          type: 'select',
                          placeholder: 'Booking Time',
                          options: [],
                        }}
                        value={selectedLoad.bookingTime ?? ''}
                        change={(e) => { }}
                        disabled
                        id={"booking-input" + selectedLoad.id}
                        inputStyle="inline"
                        label='Booking Time'
                        key={selectedLoad.id}
                      />
                    </Col>
                  </Row>
                </>
              ) : null}

              <Row>
                <Col className={classes['card-box']}>
                  <div className={classes['documents-card']} onClick={() => setShowCto(true)}>
                    <FaCheckCircle className={classes['uploaded-check']} color='var(--theme-green)' />

                    <div className={classes['card-icon']}>
                      <FaTruck size={20} color='var(--theme-blue)' />
                    </div>

                    CTO
                  </div>

                  <div className={classes['documents-card']} onClick={() => onPdfClick('Driver CTO')}>
                    {documentsUploadedMap['Driver CTO'] != null ? (
                      <FaCheckCircle className={classes['uploaded-check']} color='var(--theme-green)' />
                    ) : null}

                    <div className={classes['card-icon']}>
                      <FaTruck size={20} color='var(--theme-blue)' />
                    </div>

                    Driver CTO
                  </div>

                  {props.loadStatus === 'active' || props.loadStatus === 'completed' ? (
                    <>
                      <div className={classes['documents-card']} onClick={() => onPhotoClick('Depot Stamp')}>
                        {documentsUploadedMap['Depot Stamp'] != null ? (
                          <FaCheckCircle className={classes['uploaded-check']} color='var(--theme-green)' />
                        ) : null}

                        <div className={classes['card-icon']}>
                          <FaStamp size={20} color='var(--theme-blue)' />
                        </div>

                        Depot Stamp
                      </div>

                      <div className={classes['documents-card']} onClick={() => onPhotoClick('Seal Photo')}>
                        {documentsUploadedMap['Seal Photo'] != null ? (
                          <FaCheckCircle className={classes['uploaded-check']} color='var(--theme-green)' />
                        ) : null}

                        <div className={classes['card-icon']}>
                          <FaStamp size={20} color='var(--theme-blue)' />
                        </div>

                        Seal Photo
                      </div>

                      {props.loadStatus === 'active' ? (
                        <div className={classes['documents-card']} onClick={() => setEditingField({ field: 'sealNumber', label: 'Seal Number' })}>
                          {selectedLoad.sealNumber != null && selectedLoad.sealNumber !== '' ? (
                            <FaCheckCircle className={classes['uploaded-check']} color='var(--theme-green)' />
                          ) : null}

                          <div className={classes['card-icon']}>
                            <FaStamp size={20} color='var(--theme-blue)' />
                          </div>

                          Seal No.
                        </div>
                      ) : null}

                      <div className={classes['documents-card']} onClick={() => onPhotoClick('Container Photo')}>
                        {documentsUploadedMap['Container Photo'] != null ? (
                          <FaCheckCircle className={classes['uploaded-check']} color='var(--theme-green)' />
                        ) : null}

                        <div className={classes['card-icon']}>
                          <FaCube size={20} color='var(--theme-blue)' />
                        </div>

                        Container Photo
                      </div>

                      {props.loadStatus === 'active' ? (
                        <div className={classes['documents-card']} onClick={() => setEditingField({ field: 'containerNumber', label: 'Container Number' })}>
                          {selectedLoad.containerNumber != null && selectedLoad.containerNumber !== '' ? (
                            <FaCheckCircle className={classes['uploaded-check']} color='var(--theme-green)' />
                          ) : null}

                          <div className={classes['card-icon']}>
                            <FaCube size={20} color='var(--theme-blue)' />
                          </div>

                          Container No.
                        </div>
                      ) : null}

                      <div className={classes['documents-card']} onClick={() => onPhotoClick('Temptale 1 Photo')}>
                        {documentsUploadedMap['Temptale 1 Photo'] != null ? (
                          <FaCheckCircle className={classes['uploaded-check']} color='var(--theme-green)' />
                        ) : null}

                        <div className={classes['card-icon']}>
                          <FaTemperatureHigh size={20} color='var(--theme-blue)' />
                        </div>

                        Temptale 1 Photo
                      </div>

                      <div className={classes['documents-card']} onClick={() => onPhotoClick('Temptale 2 Photo')}>
                        {documentsUploadedMap['Temptale 2 Photo'] != null ? (
                          <FaCheckCircle className={classes['uploaded-check']} color='var(--theme-green)' />
                        ) : null}

                        <div className={classes['card-icon']}>
                          <FaTemperatureHigh size={20} color='var(--theme-blue)' />
                        </div>

                        Temptale 2 Photo
                      </div>

                      <div className={classes['documents-card']} onClick={() => onPhotoClick('VGM 1 Photo')}>
                        {documentsUploadedMap['VGM 1 Photo'] != null ? (
                          <FaCheckCircle className={classes['uploaded-check']} color='var(--theme-green)' />
                        ) : null}

                        <div className={classes['card-icon']} >
                          <FaWeightHanging size={20} color='var(--theme-blue)' />
                        </div>

                        VGM 1 Photo
                      </div>

                      <div className={classes['documents-card']} onClick={() => onPhotoClick('VGM 2 Photo')}>
                        {documentsUploadedMap['VGM 2 Photo'] != null ? (
                          <FaCheckCircle className={classes['uploaded-check']} color='var(--theme-green)' />
                        ) : null}

                        <div className={classes['card-icon']}>
                          <FaWeightHanging size={20} color='var(--theme-blue)' />
                        </div>

                        VGM 2 Photo
                      </div>

                      <div className={classes['documents-card']} onClick={() => onPhotoClick('Port Slip Photo')}>
                        {documentsUploadedMap['Port Slip Photo'] != null ? (
                          <FaCheckCircle className={classes['uploaded-check']} color='var(--theme-green)' />
                        ) : null}

                        <div className={classes['card-icon']}>
                          <FaReceipt size={20} color='var(--theme-blue)' />
                        </div>

                        Port Slip Photo
                      </div>

                      <div className={classes['documents-card']} onClick={() => onPhotoClick('Booking Photo')}>
                        {documentsUploadedMap['Booking Photo'] != null ? (
                          <FaCheckCircle className={classes['uploaded-check']} color='var(--theme-green)' />
                        ) : null}

                        <div className={classes['card-icon']}>
                          <FaReceipt size={20} color='var(--theme-blue)' />
                        </div>

                        Booking Photo
                      </div>
                    </>
                  ) : (<>
                    <div className={classes['documents-card']} onClick={() => updateLoad(selectedLoad.id, 'isActive', true)}>
                      <div className={classes['card-icon']}>
                        <FaCheckCircle size={20} color='var(--theme-green)' />
                      </div>

                      Accept Load
                    </div>
                  </>)}
                </Col>
              </Row>
            </>
          ) : null
        }
      </Container>
    </>
  );
};

export default DriverLoads;
