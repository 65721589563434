import { AxiosResponse } from 'axios';
import axiosLogin, { activate as activateService } from '../../services/axios/login';
import {
  SET_IS_LOGGED_IN,
  SET_USER
} from '../constants/auth';
import { customAlert } from './alert';
import User from '../../models/user';

export const login = (
  username: string,
  password: string,
  callback: (success: boolean, response?: AxiosResponse) => void) => {
  return (dispatch: Function) => {
    axiosLogin(username, password, (response) => {
      dispatch({
        type: SET_IS_LOGGED_IN,
        isLoggedIn: true
      })

      callback(true, response);
    }, (error) => {
      callback(false);
      dispatch(customAlert(true, error))
    });
  };
};

export const activate = (
  username: string,
  password: string,
  activationCode: string,
  callback: (success: boolean, response?: AxiosResponse) => void) => {
  return (dispatch: Function) => {
    activateService(username, password, activationCode, (response) => {
      dispatch({
        type: SET_IS_LOGGED_IN,
        isLoggedIn: true
      })

      callback(true, response);
    }, (error) => {
      callback(false);
      dispatch(customAlert(true, error))
    });
  };
};

export const logout = () => {
  return (dispatch: Function) => {
    dispatch({
      type: SET_USER,
      user: null
    });

    dispatch({
      type: SET_IS_LOGGED_IN,
      isLoggedIn: false
    })
  };
};

export const setUser = (user: User) => {
  return (dispatch: Function) => {
    dispatch({
      type: SET_USER,
      user: user
    });
  };
};
