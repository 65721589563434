import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistedStore } from './store/store';
import App from './App';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

if (window.location.hostname === 'geogrigor.com') {
  window.location.href = 'https://capecooltransport.co.za';
}

const container = document.getElementById('root')

if (container != null) {
  const root = createRoot(container)
  root.render(
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <App />
      </PersistGate>
    </Provider>
  )

} else {
  console.error('Unable to find root container')
}
