import React, { useState } from 'react';
import classes from './UserForm.module.scss';
import Input from '../../../UI/Input/Input';
import Button from '../../../UI/Button/Button';
import Loader from '../../../UI/Loader/Loader';
import checkValidity from '../../../../helpers/checkValidity';
import User from '../../../../models/user';

interface UserFormProps {
  close: () => void;
  createUser: (user: Omit<User, 'id' | 'isSuperAdmin' | 'activationCode'>) => void;
  user?: User | null;
  clients: { data: Entity[], isLoading: boolean }
}

interface Entity {
  alias: string;
  id: string;
}

interface FormItem {
  value: string;
  isValid: boolean;
  isTouched: boolean;
}

interface Form {
  [key: string]: FormItem;
}

const UserForm: React.FC<UserFormProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const clientData = props.clients.data.find(client => client.id === props.user?.clientId);

  const [formValues, setFormValues] = useState<Form>({
    firstName: { value: props.user?.firstName ?? '', isValid: true, isTouched: true },
    lastName: { value: props.user?.lastName ?? '', isValid: true, isTouched: true },
    email: { value: props.user?.email ?? '', isValid: true, isTouched: true },
    phoneNumber: { value: props.user?.phoneNumber ?? '', isValid: true, isTouched: true },
    userType: { value: props.user?.userType ?? '', isValid: true, isTouched: true },
    client: { value: clientData?.alias ?? '', isValid: true, isTouched: true },
    clientId: { value: props.user?.clientId ?? '', isValid: true, isTouched: true },
    viewPriceList: { value: props.user?.permissions?.viewPriceList === true ? 'YES' : 'NO', isValid: true, isTouched: true },
    setPreAdvised: { value: props.user?.permissions?.setPreAdvised === true ? 'YES' : 'NO', isValid: true, isTouched: true }
  });

  const updateForm = (value: string, identifier: string) => {
    setFormValues(prevState => {
      const newForm = { ...prevState };
      const newItem = { ...newForm[identifier] };

      newItem.value = value;
      newItem.isTouched = true;
      newItem.isValid = checkValidity(value, {
        required: true,
        minLength: 2,
        maxLength: 40,
        isEmail: identifier === 'email'
      });

      if (identifier === 'userType' && value !== 'client') {
        newForm.client = { value: '', isValid: false, isTouched: false };
        newForm.clientId = { value: '', isValid: false, isTouched: false };
      }

      newForm[identifier] = newItem;
      return newForm;
    });
  };

  const createUser = () => {
    if (formValues.firstName.value.trim() === '' ||
      formValues.lastName.value.trim() === '' ||
      formValues.email.value.trim() === '') {
      return;
    }

    const permissions = {
      viewPriceList: formValues.viewPriceList.value === 'YES' ? true : false,
      setPreAdvised: formValues.setPreAdvised.value === 'YES' ? true : false
    }

    setIsLoading(true);
    props.createUser({
      alias: `${formValues.firstName.value} ${formValues.lastName.value}`,
      phoneNumber: formValues.phoneNumber.value,
      clientId: formValues.clientId.value || null,
      userType: formValues.userType.value,
      email: formValues.email.value,
      firstName: formValues.firstName.value,
      lastName: formValues.lastName.value,
      permissions
    });
  };

  return (
    <div className={classes['create-new-user']}>
      <h6>{props.user ? 'Edit User' : 'Create New User'}</h6>

      <form onSubmit={(e) => {
        e.preventDefault();
        createUser();
      }}>
        <div className={classes['input-group']}>
          <Input
            elementType='text'
            elementConfig={{
              type: 'text',
              placeholder: 'First Name'
            }}
            value={formValues.firstName.value}
            invalid={!formValues.firstName.isValid}
            shouldValidate
            touched={formValues.firstName.isTouched}
            change={(e) => updateForm(e.target.value, 'firstName')}
            id='firstName-input'
            label='First Name'
            inputStyle='main'
          />

          <Input
            elementType='text'
            elementConfig={{
              type: 'text',
              placeholder: 'Last Name'
            }}
            value={formValues.lastName.value}
            invalid={!formValues.lastName.isValid}
            shouldValidate
            touched={formValues.lastName.isTouched}
            change={(e) => updateForm(e.target.value, 'lastName')}
            id='lastName-input'
            label='Last Name'
            inputStyle='main'
          />

          <Input
            elementType='text'
            elementConfig={{
              type: 'text',
              placeholder: 'Email'
            }}
            value={formValues.email.value}
            invalid={!formValues.email.isValid}
            shouldValidate
            touched={formValues.email.isTouched}
            change={(e) => updateForm(e.target.value, 'email')}
            id='email-input'
            label='Email'
            inputStyle='main'
          />

          <Input
            elementType='text'
            elementConfig={{
              type: 'text',
              placeholder: 'Phone Number'
            }}
            value={formValues.phoneNumber.value}
            invalid={!formValues.phoneNumber.isValid}
            shouldValidate
            touched={formValues.phoneNumber.isTouched}
            change={(e) => updateForm(e.target.value, 'phoneNumber')}
            id='phoneNumber-input'
            label='Phone Number'
            inputStyle='main'
          />

          <Input
            elementType='select'
            elementConfig={{
              type: 'select',
              placeholder: 'User Type',
              options: [
                { value: 'admin', displayValue: 'Admin' },
                { value: 'client', displayValue: 'Client' },
                { value: 'driver', displayValue: 'Driver' }
              ]
            }}
            value={formValues.userType.value}
            invalid={!formValues.userType.isValid}
            shouldValidate
            touched={formValues.userType.isTouched}
            change={(e) => updateForm(e.target.value, 'userType')}
            id='userType-input'
            label='User Type'
            inputStyle='main'
          />

          {formValues.userType.value === 'client' && (
            <Input
              elementType='select'
              allowEmpty={true}
              elementConfig={{
                type: 'select',
                placeholder: 'Client',
                options: [
                  ...props.clients.data.map(
                    client => ({ value: client, displayValue: client.alias })
                  )
                ]
              }}
              isLoading={props.clients.isLoading}
              value={formValues.client.value}
              invalid={!formValues.client.isValid}
              shouldValidate
              touched={formValues.client.isTouched}
              change={(e) => {
                updateForm(e.target.value.alias, 'client')
                updateForm(e.target.value.id, 'clientId')
              }}
              id='client-input'
              label='Client'
              inputStyle='main'
            />
          )}

          <div className={classes['permissions-section']}>
            <h6>Permissions</h6>

            <div className={classes['permissions-section-item']}>
              <div>View Price List</div>

              <Input
                elementType='checkbox'
                elementConfig={{
                  type: 'checkbox'
                }}
                value={formValues.viewPriceList.value}
                invalid={false}
                shouldValidate={false}
                touched={true}
                change={(e) => updateForm(e.target.value ? 'YES' : 'NO', 'viewPriceList')}
                id='viewPriceList-input'
                inputStyle='main'
                labelHidden={true}
              />
            </div>

            <div className={classes['permissions-section-item']}>
              <div>Set Pre-Advised</div>

              <Input
                elementType='checkbox'
                elementConfig={{
                  type: 'checkbox'
                }}
                value={formValues.setPreAdvised.value}
                invalid={false}
                shouldValidate={false}
                touched={true}
                change={(e) => updateForm(e.target.value ? 'YES' : 'NO', 'setPreAdvised')}
                id='setPreAdvised-input'
                inputStyle='main'
                labelHidden={true}
              />
            </div>
          </div>
        </div>

        <div className={classes.buttons}>
          <div className='pointer no-select' onClick={props.close}>Cancel</div>
          {isLoading ? (
            <Loader size='small' />
          ) : (
            <Button
              type='submit'
              buttonStyle='main'
              text={props.user ? 'Save' : 'Create User'}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default UserForm;
