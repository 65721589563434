import React, { useState, useEffect, useCallback } from 'react';
import classes from './PriceList.module.scss';
import get from '../../../../services/axios/get';
import Loader from '../../../UI/Loader/Loader';
import Input from '../../../UI/Input/Input';
import Button from '../../../UI/Button/Button';
import DatePicker from '../../../UI/DatePicker/DatePicker';
import Modal from '../../../../containers/Modal/Modal';
import put from '../../../../services/axios/put';
import Alert from '../../../UI/Alert/Alert';
import { useAppSelector } from '../../../../store/hooks';
import { FaDownload } from 'react-icons/fa';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PriceListPdf from './PriceListPdf';

interface PriceListProps {
  clientName?: string;
  clientId: string;
}

interface LoadPoint {
  id: string;
  alias: string;
  region: string;
}

interface PriceItems {
  [loadPointId: string]: number;
  fuelLevyPercentage: number;
}

interface RegionalPriceLists {
  [region: string]: {
    [date: string]: PriceItems;
  };
}

const getFormattedDate = (date: Date) => date.getFullYear() +
  '-' +
  String(date.getMonth() + 1).padStart(2, '0') +
  '-' +
  String(date.getDate()).padStart(2, '0');

const PriceList: React.FC<PriceListProps> = ({ clientName, clientId }) => {
  const [fuelLevyPercentage, setFuelLevyPercentage] = useState<number>(0);
  const [loadPoints, setLoadPoints] = useState<LoadPoint[]>([]);
  const [priceItems, setPriceItems] = useState<any>({
    fuelLevyPercentage: 0,
    additionalCharges: {
      ctoFee: 0,
      preAdvisedFee: 0,
      genset: 0,
      overnightGenset: 0,
      dualLoadOnRoute: 0,
      dualLoadOffRoute: 0,
      weighbridgeSurcharge: 0,
      satlWeighbridge: 0,
      crossHaul: 0,
      belconPickup: 0
    }
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [showDateModal, setShowDateModal] = useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const user = useAppSelector(state => state.auth.user)
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [regionalPriceLists, setRegionalPriceLists] = useState<RegionalPriceLists>({});

  const getLoadPoints = useCallback(() => {
    get('/load-point', { isDischarge: false }, (res) => {
      setLoadPoints(res.data.sort((a: any, b: any) => a.alias.localeCompare(b.alias)));
      setIsLoading(false);
    });
  }, []);

  const getPriceLists = useCallback(() => {
    setIsLoading(true);
    setSelectedDate('');

    get(`/price-list/${clientId}`, {}, (res) => {
      const data = res.data;

      const regionData: RegionalPriceLists = {};
      Object.keys(data ?? {}).forEach(key => {
        if (key !== 'entityType' && key !== 'id' && key !== 'clientName') {
          regionData[key] = data[key];
        }
      });

      setRegionalPriceLists(regionData);
      const availableRegions = ['WC', 'EC'];

      const firstRegion = availableRegions[0];
      setSelectedRegion(firstRegion);

      const dates = Object.keys(regionData[firstRegion] ?? {}).sort();
      if (dates.length > 0) {
        const latestDate = dates[dates.length - 1];
        setSelectedDate(latestDate);
        setPriceItems(regionData[firstRegion][latestDate]);
        setFuelLevyPercentage(regionData[firstRegion][latestDate].fuelLevyPercentage || 0);
      }


      setIsLoading(false);
    }, (error: any) => {
      setIsLoading(false);
    });
  }, [clientId]);

  const createNewPriceList = (date: string) => {
    const dates = Object.keys(regionalPriceLists[selectedRegion] ?? {});
    const latestDate = dates.length > 0 ? dates[dates.length - 1] : '';
    const newPriceList = latestDate ? { ...regionalPriceLists[selectedRegion][latestDate] } : { fuelLevyPercentage: 0 };

    const updatedPriceLists = {
      ...regionalPriceLists,
      [selectedRegion]: {
        ...regionalPriceLists[selectedRegion],
        [date]: newPriceList,
      },
    };

    put(`/price-list/${clientId}`, { ...updatedPriceLists, clientName }, (res) => {
      setIsLoading(true);
      getPriceLists();
    });
  };

  const openDateModal = () => setShowDateModal(true);
  const closeDateModal = () => setShowDateModal(false);

  useEffect(() => {
    getLoadPoints();
    getPriceLists();
  }, [getLoadPoints, getPriceLists]);

  useEffect(() => {
    getPriceLists();
  }, [clientId, getPriceLists]);

  const handleBaseRateChange = (loadPointId: string, value: number) => {
    setPriceItems((prevItems: any) => ({ ...prevItems, [loadPointId]: value }));
  };

  const handleNetAmountChange = (loadPointId: string, value: number) => {
    setPriceItems((prevItems: any) => ({
      ...prevItems,
      netOverrides: {
        ...prevItems.netOverrides,
        [loadPointId]: value
      }
    }));
  };

  const handleFuelLevyChange = (value: string) => {
    const numericValue = parseFloat(value);
    setFuelLevyPercentage(!isNaN(numericValue) ? numericValue : 0);
    setPriceItems((prevItems: any) => ({ ...prevItems, fuelLevyPercentage: numericValue }));
  };

  const handleAdditionalChargeChange = (chargeName: string, value: string) => {
    let numericValue = parseFloat(value.split(' ')[1] ?? '0');

    if (chargeName === 'dualLoadOffRoute') {
      numericValue = parseFloat(value.split(' ')[0] ?? '0');
    }

    console.log(numericValue);

    setPriceItems((prevItems: any) => ({
      ...prevItems,
      additionalCharges: {
        ...prevItems.additionalCharges,
        [chargeName]: !isNaN(numericValue) ? numericValue : 0
      }
    }));
  };

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const region = event.target.value;
    setSelectedRegion(region);

    const dates = Object.keys(regionalPriceLists[region] ?? {}).sort();
    if (dates.length > 0) {
      const latestDate = dates[dates.length - 1];
      setSelectedDate(latestDate);
      setPriceItems(regionalPriceLists[region][latestDate]);
      setFuelLevyPercentage(regionalPriceLists[region][latestDate].fuelLevyPercentage || 0);
    } else {
      setSelectedDate('');
      setPriceItems({});
      setFuelLevyPercentage(0);
    }
  };

  const handleDateSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const date = event.target.value;
    setSelectedDate(date);
    const selectedPriceItems = regionalPriceLists[selectedRegion][date] ?? {};
    setPriceItems(selectedPriceItems);
    setFuelLevyPercentage(selectedPriceItems.fuelLevyPercentage ?? 0);
  };

  const savePriceList = () => {
    const updatedRegionalPriceLists = {
      ...regionalPriceLists,
      [selectedRegion]: {
        ...regionalPriceLists[selectedRegion],
        [selectedDate]: { ...priceItems, fuelLevyPercentage }
      }
    };

    put(`/price-list/${clientId}`, {
      entityType: 'priceList',
      clientName,
      ...updatedRegionalPriceLists
    }, () => {
      setRegionalPriceLists(updatedRegionalPriceLists);
    });
  };

  useEffect(() => {
    if (selectedDate != null && selectedDate !== '') {
      setPriceItems(regionalPriceLists[selectedRegion]?.[selectedDate]);
      setFuelLevyPercentage(regionalPriceLists[selectedRegion]?.[selectedDate]?.fuelLevyPercentage ?? 0);
    }
  }, [selectedDate]);

  return (
    <div className={classes['price-list']}>
      {isLoading ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={classes['date-selection']}>
            <select
              className={classes['date-dropdown']}
              value={selectedRegion}
              onChange={handleRegionChange}
            >
              {['WC', 'EC'].map((region) => (
                <option key={region} value={region}>
                  {region}
                </option>
              ))}
            </select>

            {selectedDate && (
              <select
                className={classes['date-dropdown']}
                value={selectedDate}
                onChange={handleDateSelection}
              >
                {Object.keys(regionalPriceLists[selectedRegion] ?? {})
                  .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
                  .map((date) => (
                    <option key={date} value={date}>
                      {date}
                    </option>
                  ))}
              </select>
            )}

            {user?.userType === 'admin' && (
              <Button
                buttonStyle='main'
                type='button'
                text='Create New Price List'
                click={openDateModal}
              />
            )}
          </div>

          {selectedDate !== '' && selectedRegion != null && (
            <>
              <div className='d-flex justify-content-between'>
                {user?.userType === 'admin' ? (
                  <div className={classes['fuel-levy']}>
                    <label htmlFor="fuel-levy">Fuel Levy %:</label>
                    <Input
                      elementType={'input'}
                      elementConfig={{
                        type: 'number',
                        step: '1',
                        min: '-100'
                      }}
                      value={fuelLevyPercentage.toString()}
                      change={(e: React.ChangeEvent<HTMLInputElement>) => handleFuelLevyChange(e.target.value)}
                      id={'fuel-levy'}
                      labelHidden
                      inputStyle='main'
                    />
                  </div>
                ) : (
                  <div className={classes['fuel-levy']}>
                    <label htmlFor="fuel-levy">Fuel Levy:</label>
                    <span>{fuelLevyPercentage.toString()}%</span>
                  </div>
                )}

                <PDFDownloadLink
                  document={
                    <PriceListPdf
                      clientName={clientName}
                      loadPoints={loadPoints}
                      priceItems={priceItems}
                      fuelLevyPercentage={fuelLevyPercentage}
                      selectedDate={selectedDate}
                    />
                  }
                  fileName={`PriceList.${clientName}.${selectedDate}.pdf`}
                >
                  {({ loading }) => (
                    <div className={['d-flex align-items-center gap-2', classes['download-button']].join(' ')}>
                      <FaDownload /> {loading ? 'Loading...' : 'Download as PDF'}
                    </div>
                  )}
                </PDFDownloadLink>
              </div>

              <table className={classes['price-table']}>
                <thead>
                  <tr>
                    <th>Load Point</th>
                    {user?.userType === 'admin' ? (
                      <>
                        <th>Base Rate</th>
                        <th>Fuel Levy %</th>
                      </>
                    ) : (
                      <th>Rate</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {loadPoints.map((loadPoint) => {
                    if ((user?.userType !== 'admin' && priceItems[loadPoint.alias] == null ) || loadPoint.region !== selectedRegion) {
                      return null;
                    }

                    return (
                      <tr key={loadPoint.alias}>
                        <td>{loadPoint.alias}</td>

                        {user?.userType === 'admin' ? (
                          <>
                            <td>
                              <Input
                                elementType={'currency'}
                                elementConfig={{
                                  placeholder: 'R 0.00',
                                }}
                                value={`R ${(priceItems?.[loadPoint.alias])?.toString()}`}
                                change={(e) => handleBaseRateChange(loadPoint.alias, parseFloat((e.target.value ?? '').split(' ')[1]))}
                                id='amount-input'
                                inputStyle='main'
                                labelHidden
                                confirm={() => setShowSaveAlert(true)}
                                confirmOnBlur={false}
                              />
                            </td>
                            <td>
                              <Input
                                elementType={'currency'}
                                elementConfig={{
                                  placeholder: 'R 0.00',
                                }}
                                value={priceItems?.netOverrides?.[loadPoint.alias]
                                  ? `R ${(priceItems?.netOverrides?.[loadPoint.alias]).toFixed(0)}`
                                  : `R ${(priceItems?.[loadPoint.alias] * (1 + fuelLevyPercentage / 100)).toFixed(0)}`}
                                change={(e) => handleNetAmountChange(loadPoint.alias, parseFloat((e.target.value ?? '').split(' ')[1]))}
                                id='net-amount-input'
                                inputStyle='main'
                                labelHidden
                                confirm={() => setShowSaveAlert(true)}
                                confirmOnBlur={false}
                              />
                            </td>
                          </>
                        ) : (
                          <td>
                            {priceItems?.netOverrides?.[loadPoint.alias]
                              ? `R ${(priceItems.netOverrides?.[loadPoint.alias]).toFixed(0)}`
                              : `R ${(priceItems[loadPoint.alias] * (1 + fuelLevyPercentage / 100)).toFixed(0)}`}
                          </td>
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </table>

              <h3>Additional Charges</h3>
              <table className={classes['price-table']}>
                <thead>
                  <tr>
                    <th>Charge Type</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CTO Fee</td>
                    <td>
                      {user?.userType === 'admin' ? (
                        <Input
                          elementType={'currency'}
                          elementConfig={{
                            placeholder: 'R 0',
                          }}
                          value={`R ${priceItems.additionalCharges?.ctoFee?.toFixed(0).toString() ?? '0'}`}
                          change={(e) => handleAdditionalChargeChange('ctoFee', e.target.value)}
                          id='cto-fee-input'
                          inputStyle='main'
                          labelHidden
                          confirm={() => setShowSaveAlert(true)}
                          confirmOnBlur={false}
                        />
                      ) : (
                        <div>
                          R {priceItems.additionalCharges?.ctoFee?.toFixed(0).toString() ?? '0'}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Pre Advised Fee</td>
                    <td>
                      {user?.userType === 'admin' ? (
                        <Input
                          elementType={'currency'}
                          elementConfig={{
                            placeholder: 'R 0',
                          }}
                          value={`R ${priceItems.additionalCharges?.preAdvisedFee?.toFixed(0).toString() ?? '0'}`}
                          change={(e) => handleAdditionalChargeChange('preAdvisedFee', e.target.value)}
                          id='pre-advised-fee-input'
                          inputStyle='main'
                          labelHidden
                          confirm={() => setShowSaveAlert(true)}
                          confirmOnBlur={false}
                        />
                      ) : (
                        <div>
                          R {priceItems.additionalCharges?.preAdvisedFee?.toFixed(0).toString() ?? '0'}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Genset</td>
                    <td>
                      {user?.userType === 'admin' ? (
                        <Input
                          elementType={'currency'}
                          elementConfig={{
                            placeholder: 'R 0',
                          }}
                          value={`R ${priceItems.additionalCharges?.genset?.toFixed(0).toString() ?? '0'}`}
                          change={(e) => handleAdditionalChargeChange('genset', e.target.value)}
                          id='genset-input'
                          inputStyle='main'
                          labelHidden
                          confirm={() => setShowSaveAlert(true)}
                          confirmOnBlur={false}
                        />
                      ) : (
                        <div>
                          R {priceItems.additionalCharges?.genset?.toFixed(0).toString() ?? '0'}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Overnight Genset</td>
                    <td>
                      {user?.userType === 'admin' ? (
                        <Input
                          elementType={'currency'}
                          elementConfig={{
                            placeholder: 'R 0',
                          }}
                          value={`R ${priceItems.additionalCharges?.overnightGenset?.toFixed(0).toString() ?? '0'}`}
                          change={(e) => handleAdditionalChargeChange('overnightGenset', e.target.value)}
                          id='overnight-genset-input'
                          inputStyle='main'
                          labelHidden
                          confirm={() => setShowSaveAlert(true)}
                          confirmOnBlur={false}
                        />
                      ) : (
                        <div>
                          R {priceItems.additionalCharges?.overnightGenset?.toFixed(0).toString() ?? '0'}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Dual Load (On Route)</td>
                    <td>
                      {user?.userType === 'admin' ? (
                        <Input
                          elementType={'currency'}
                          elementConfig={{
                            placeholder: 'R 0',
                          }}
                          value={`R ${priceItems.additionalCharges?.dualLoadOnRoute?.toFixed(0).toString() ?? '0'}`}
                          change={(e) => handleAdditionalChargeChange('dualLoadOnRoute', e.target.value)}
                          id='dual-load-on-route-input'
                          inputStyle='main'
                          labelHidden
                          confirm={() => setShowSaveAlert(true)}
                          confirmOnBlur={false}
                        />
                      ) : (
                        <div>
                          R {priceItems.additionalCharges?.dualLoadOnRoute?.toFixed(0).toString() ?? '0'}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Dual Load (Off Route) % of 2nd load point</td>
                    <td>
                      {user?.userType === 'admin' ? (
                        <div className={classes['dual-load-off-route']}>
                          <Input
                            elementType={'input'}
                            elementConfig={{
                              type: 'number',
                              step: '1',
                              min: '-100'
                            }}
                            value={`${priceItems.additionalCharges?.dualLoadOffRoute?.toFixed(0).toString() ?? '0'}`}
                            change={(e) => handleAdditionalChargeChange('dualLoadOffRoute', e.target.value)}
                            id='dual-load-off-route-input'
                            inputStyle='main'
                            labelHidden
                            confirm={() => setShowSaveAlert(true)}
                            confirmOnBlur={false}
                          /> <span>%</span>
                        </div>
                      ) : (
                        <div>
                          {priceItems.additionalCharges?.dualLoadOffRoute?.toFixed(0).toString() ?? '0'} %
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Weighbridge Surcharge</td>
                    <td>
                      {user?.userType === 'admin' ? (
                        <Input
                          elementType={'currency'}
                          elementConfig={{
                            placeholder: 'R 0',
                          }}
                          value={`R ${priceItems.additionalCharges?.weighbridgeSurcharge?.toFixed(0).toString() ?? '0'}`}
                          change={(e) => handleAdditionalChargeChange('weighbridgeSurcharge', e.target.value)}
                          id='weighbridge-surcharge-input'
                          inputStyle='main'
                          labelHidden
                          confirm={() => setShowSaveAlert(true)}
                          confirmOnBlur={false}
                        />
                      ) : (
                        <div>
                          R {priceItems.additionalCharges?.weighbridgeSurcharge?.toFixed(0).toString() ?? '0'}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>SATL Weighbridge</td>
                    <td>
                      {user?.userType === 'admin' ? (
                        <Input
                          elementType={'currency'}
                          elementConfig={{
                            placeholder: 'R 0',
                          }}
                          value={`R ${priceItems.additionalCharges?.satlWeighbridge?.toFixed(0).toString() ?? '0'}`}
                          change={(e) => handleAdditionalChargeChange('satlWeighbridge', e.target.value)}
                          id='satl-weighbridge-input'
                          inputStyle='main'
                          labelHidden
                          confirm={() => setShowSaveAlert(true)}
                          confirmOnBlur={false}
                        />
                      ) : (
                        <div>
                          R {priceItems.additionalCharges?.satlWeighbridge?.toFixed(0).toString() ?? '0'}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Cross Haul</td>
                    <td>
                      {user?.userType === 'admin' ? (
                        <Input
                          elementType={'currency'}
                          elementConfig={{
                            placeholder: 'R 0',
                          }}
                          value={`R ${priceItems.additionalCharges?.crossHaul?.toFixed(0).toString() ?? '0'}`}
                          change={(e) => handleAdditionalChargeChange('crossHaul', e.target.value)}
                          id='cross-haul-input'
                          inputStyle='main'
                          labelHidden
                          confirm={() => setShowSaveAlert(true)}
                          confirmOnBlur={false}
                        />
                      ) : (
                        <div>
                          R {priceItems.additionalCharges?.crossHaul?.toFixed(0).toString() ?? '0'}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Belcon/ICR/Milltrans Pick Up</td>
                    <td>
                      {user?.userType === 'admin' ? (
                        <Input
                          elementType={'currency'}
                          elementConfig={{
                            placeholder: 'R 0',
                          }}
                          value={`R ${priceItems.additionalCharges?.belconPickup?.toFixed(0).toString() ?? '0'}`}
                          change={(e) => handleAdditionalChargeChange('belconPickup', e.target.value)}
                          id='belcon-pickup-input'
                          inputStyle='main'
                          labelHidden
                          confirm={() => setShowSaveAlert(true)}
                          confirmOnBlur={false}
                        />
                      ) : (
                        <div>
                          R {priceItems.additionalCharges?.belconPickup?.toFixed(0).toString() ?? '0'}
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>


              {user?.userType === 'admin' && (
                <div className={classes.footer}>
                  <Button
                    buttonStyle='main'
                    type='button'
                    text='Save'
                    click={() => setShowSaveAlert(true)}
                  />
                </div>
              )}
            </>
          )}

          <Alert
            confirm={(confirmation) => {
              if (confirmation) {
                savePriceList();
              }
              setShowSaveAlert(false);
            }}
            message={<div>Are you sure you want to save this price list?</div>}
            isOpen={showSaveAlert}
            isConfirm
          />

          {showDateModal && (
            <Modal close={closeDateModal} style={{ width: '400px' }} stacked={false} stretch>
              <div>Select a Date for New Price List</div>
              <DatePicker
                defaultDate={new Date()}
                callback={(date, isValid) => {
                  if (isValid && date !== null) {
                    const formattedDate = getFormattedDate(date);
                    createNewPriceList(formattedDate);
                    closeDateModal();
                  }
                }}
                invalidMessage='Invalid date'
              />
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default PriceList;
