import User from '../../models/user';
import {
  SET_USER,
  SET_IS_LOGGED_IN
} from '../constants/auth';


type AuthState = {
  user: User | undefined;
  isLoggedIn: boolean;
};

type AuthAction = {
  type: typeof SET_USER | typeof SET_IS_LOGGED_IN
  user?: User;
  isLoggedIn?: boolean;
};

const initiateState: AuthState = {
  user: undefined,
  isLoggedIn: false
};

const auth = (state: AuthState = initiateState, action: AuthAction): AuthState => {
  if (action.type === SET_USER) {
    return {
      ...state,
      user: action.user
    };
  }

  if (action.type === SET_IS_LOGGED_IN) {
    return {
      ...state,
      isLoggedIn: action.isLoggedIn ?? false
    };
  }

  return state;
};

export default auth;
