import React, { useRef, useState } from 'react';
import classes from './LoadItem.module.scss';
import Load from '../../../../models/load';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { CgMenu } from 'react-icons/cg';
import Input from '../../../UI/Input/Input';
import Alert from '../../../UI/Alert/Alert';
import Driver from '../../../../models/driver';
import Status from '../../../../models/status';
import Account from '../../../../models/account';
import PluginDepot from '../../../../models/pluginDepot';
import { FaCalendar, FaUpload } from 'react-icons/fa';
import Modal from '../../../../containers/Modal/Modal';
import DatePicker from '../../../UI/DatePicker/DatePicker';
import get from '../../../../services/axios/get';
import post from '../../../../services/axios/post';
import { customAlert } from '../../../../store/actions/alert';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import Loader from '../../../UI/Loader/Loader';
import Button from '../../../UI/Button/Button';

interface LoadItemProps {
  shade: 'even' | 'odd';
  load: Load;
  deleteLoad: (id: string) => void;
  completeLoad: (id: string) => void;
  unCompleteLoad: (id: string, loadStatus: 'unassigned' | 'assigned' | 'completed' | 'plugged') => void;
  edit: (load: Load) => void;
  showAdditionalCharges: (load: Load) => void;
  showDocuments: (load: Load) => void;
  showInvoicing: (load: Load) => void;
  loadStatus: 'unassigned' | 'assigned' | 'completed' | 'plugged',
  putLoad: (load: Load, loadId: string) => void;
  createLoad: (load: Load) => void;
  drivers: { drivers: Driver[], isLoading: boolean }
  statuses: { statuses: Status[], isLoading: boolean },
  refresh: () => void;
  plugLoad: (id: string) => void
  accounts: { accounts: Account[], isLoading: boolean },
  pluginDepots: { pluginDepots: PluginDepot[], isLoading: boolean },
  isFull?: boolean
  isDuplicate?: boolean
  priceListPrice: string | null
  userType?: string;
};

const LoadItem: React.FC<LoadItemProps> = (props) => {
  const user = useAppSelector(state => state.auth.user);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [editState, setEditState] = useState<{ field: string | null; value: string | null; confirmText: string | null, options: string[] }>({
    field: null,
    value: null,
    confirmText: null,
    options: []
  });
  const [showXHauledDatePicker, setShowXHauledDatePicker] = useState(false);
  const [showDatePluggedPicker, setShowDatePluggedPicker] = useState(false);
  const [isUploadingBookingPhoto, setIsUploadingBookingPhoto] = useState(false);
  const [showETATimePicker, setShowETATimePicker] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const fileInputRef: any = useRef(null);
  const dispatch = useAppDispatch();

  let defaultLoadDate = new Date();
  let maxLoadDate = new Date();
  let minLoadDate = new Date();

  defaultLoadDate.setMonth(defaultLoadDate.getMonth());
  maxLoadDate.setMonth(maxLoadDate.getMonth() + 24);
  minLoadDate.setMonth(minLoadDate.getMonth() - 6);

  const toggleMenu = () => setMenuIsOpen(prevState => !prevState);
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
    setEditState(prev => ({ ...prev, value: event.target.value, field }));
  };
  const confirmEdit = (confirm: boolean, field?: string, value?: string, optionIndex?: number) => {
    let updateValues = { ...editState }

    if (field != null && value != null) {
      updateValues.field = field;
      updateValues.value = value;
    }

    if (confirm && updateValues.field !== null && updateValues.value !== null) {

      const updatedLoad = { ...props.load, [updateValues.field]: updateValues.value };

      if (field === 'preAdvised') {
        if (optionIndex === 0) {
          updatedLoad.dummyPreAdvised = false;
          updatedLoad.preAdvised = false;
        } else if (optionIndex === 1) {
          updatedLoad.dummyPreAdvised = true;
          updatedLoad.preAdvised = false;
        } else if (optionIndex === 2) {
          updatedLoad.dummyPreAdvised = false;
          updatedLoad.preAdvised = true;
        }
      }

      if (updateValues.field === 'driver') {
        if (updatedLoad.loadStatus === 'plugged') {
          updatedLoad.loadDate = updatedLoad.dateXHauled
        }

        if (updateValues.value === '') {
          delete updatedLoad.driver
        }

        updatedLoad.loadStatus = updateValues.value !== '' ? 'assigned' : 'unassigned';
      }

      if (updateValues.field === 'etaTime') {
        updatedLoad.status = selectedStatus ?? updatedLoad.status
      }

      props.putLoad(updatedLoad, props.load.id);
    } else {
      props.refresh();
    }

    setEditState({ field: null, value: null, confirmText: null, options: [] });
  };

  const initiateEdit = (
    field: string,
    value: string,
    confirmText: string,
    displayValue?: string
  ) => {
    let adjustedValue = value
    const options: string[] = []

    if (value == null || value === '') {
      adjustedValue = 'a blank value'
    }

    if (field === 'preAdvised') {
      options.push('Not pre-advised')
      options.push('Dummy pre-advised')
      options.push('Pre-advised')
      confirmText = 'Set pre-advised status'
    }

    setEditState({
      field, value,
      confirmText: confirmText.replace(
        '{value}', displayValue ?? adjustedValue
      ),
      options
    });
  };

  const uploadBookingPhoto = (file: File) => {
    setIsUploadingBookingPhoto(true);
    const fileName = `Booking Photo.${props.load.clientRef}.jpeg`

    get(
      '/document/upload',
      {
        documentName: fileName,
        loadId: props.load.id
      },
      async (response) => {
        const uploadResponse = await fetch(response.data.uploadUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type
          }
        });

        if (uploadResponse.ok) {
          post(
            '/document',
            {
              documentName: fileName,
              loadId: props.load.id
            },
            (response) => {
              if (fileInputRef.current != null) {
                fileInputRef.current.value = '';
              }

              setIsUploadingBookingPhoto(false);
              props.refresh()
            },
            (error: any) => {
              setIsUploadingBookingPhoto(false);
              dispatch(customAlert(true, error))
            }
          )
        } else {
          setIsUploadingBookingPhoto(false);
          dispatch(customAlert(true, 'Upload failed'))
        }
      },
      () => { }
    )
  }

  const charges: [any] = props.load.charges === undefined ? [] : JSON.parse(props.load.charges)

  return (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={(event) => {
          const file = event?.target?.files?.[0];

          if (!file) {
            return;
          }


          uploadBookingPhoto(file);
        }}
      />

      {showXHauledDatePicker === true ? (
        <Modal close={() => setShowXHauledDatePicker(false)}>
          <DatePicker
            defaultDate={defaultLoadDate}
            maxDate={maxLoadDate}
            minDate={minLoadDate}
            callback={(date, isValid) => {
              if (date == null) {
                confirmEdit(true, 'dateXHauled', '')
              } else if (isValid) {
                confirmEdit(true, 'dateXHauled', date.toISOString())
              }
            }}
            invalidMessage='Invalid date'
            showClear
          />
        </Modal>
      ) : null}

      {showDatePluggedPicker === true ? (
        <Modal close={() => setShowDatePluggedPicker(false)}>
          <DatePicker
            defaultDate={defaultLoadDate}
            maxDate={maxLoadDate}
            minDate={minLoadDate}
            callback={(date, isValid) => {
              if (date == null) {
                confirmEdit(true, 'datePlugged', '')
              } else if (isValid) {
                confirmEdit(true, 'datePlugged', date.toISOString())
              }
            }}
            invalidMessage='Invalid date'
            showClear
          />
        </Modal>
      ) : null}

      {showETATimePicker === true ? (
        <Modal close={() => setShowETATimePicker(false)}>
          <div className={classes.etaTimePicker}>
            <h6>Select ETA Time</h6>
            <Input
              id="etaTime"
              elementType={'select'}
              allowEmpty={true}
              elementConfig={{
                type: 'select',
                placeholder: 'ETA Time',
                options: Array.from({ length: 24 }, (_, i) => ({
                  value: i.toString().padStart(2, '0') + ":00",
                  displayValue: i.toString().padStart(2, '0') + ":00"
                }))
              }}
              value={editState.field === 'etaTime' ? editState.value ?? '' : props.load.etaTime ?? ''}
              change={(e) => handleValueChange(e, 'etaTime')}
              inputStyle="main"
              labelHidden
            />
            <div className={classes.etaButtons}>
              <Button
                type="button"
                buttonStyle="main"
                text="Confirm"
                click={() => {
                  if (selectedStatus) {
                    confirmEdit(true, 'etaTime', editState.value ?? props.load.etaTime);
                    setShowETATimePicker(false);
                  }
                }}
              />
              <div onClick={() => setShowETATimePicker(false)} className={classes.cancel}>
                Cancel
              </div>
            </div>
          </div>
        </Modal>
      ) : null}


      <Alert
        confirm={(confirm, optionIndex) => confirmEdit(
          confirm, editState.field ?? undefined, editState.value ?? undefined, optionIndex
        )}
        message={<div>{editState.confirmText}</div>}
        isOpen={editState.confirmText != null}
        isConfirm
        options={editState.options}
      />

      {props.isFull ? (
        <div className={[classes['full-load'], classes[props.shade]].join(' ')}>
          {props.userType !== 'client' && (
            <>
              <div>{props.load.client}{props.load.handler ? ` (${props.load.handler.firstName})` : ''}</div>
              <div>
                <Input
                  elementType="select"
                  allowEmpty={true}
                  elementConfig={{
                    type: 'select',
                    placeholder: 'Driver',
                    options: [
                      ...props.drivers.drivers.map(
                        driver => ({
                          value: {
                            alias: driver.alias,
                            phoneNumber: driver.phoneNumber,
                            id: driver.id
                          }, displayValue: driver.alias
                        }))
                    ]
                  }}
                  value={props.load.driver?.alias ?? ''}
                  change={(e) => {
                    initiateEdit(
                      'driver',
                      e.target.value,
                      `Are you sure you want to assign this load to {value}?`,
                      e.target.value.alias
                    );
                  }}
                  id="driver-input"
                  inputStyle="main"
                  labelHidden={true}
                />
              </div>
            </>
          )}

          <div>{props.load.loadDate !== '' && props.load.loadDate != null ? new Date(props.load.loadDate).toDateString() : null}</div>
          <div>
            {props.userType === 'client' ? (<div>{props.load.status}</div>) : (<Input
              elementType="select"
              allowEmpty={true}
              elementConfig={{
                type: 'select',
                placeholder: 'Load Status',
                options: [
                  ...props.statuses.statuses.map(status => ({
                    value: status.alias,
                    displayValue: status.alias,
                  })),
                  { value: 'EN ROUTE TO 1st LP', displayValue: 'EN ROUTE TO 1st LP' },
                  { value: 'EN ROUTE TO 2nd LP', displayValue: 'EN ROUTE TO 2nd LP' },
                  { value: 'EN ROUTE TO PORT', displayValue: 'EN ROUTE TO PORT' }
                ],
              }}
              value={props.load.status ?? ''}
              change={(e) => {
                const statusValue = e.target.value;
                // Check if it's one of our special statuses that need ETA
                if (
                  statusValue === 'EN ROUTE TO 1st LP' ||
                  statusValue === 'EN ROUTE TO 2nd LP' ||
                  statusValue === 'EN ROUTE TO PORT'
                ) {
                  setSelectedStatus(statusValue);
                  setShowETATimePicker(true);
                } else {
                  initiateEdit('status', statusValue, `Are you sure you want to set the load status to {value}?`);
                }
              }}
              id="status-input"
              inputStyle="main"
              labelHidden={true}
            />)}
          </div>
          <div>{props.load.emptyDepot}</div>
          <div>{props.load.loadPoint1} {props.load.status === 'En route to 1st LP' && props.load.etaTime && (
            <div className={classes.etaTime}>ETA: {props.load.etaTime}</div>
          )}</div>
          <div>{props.load.loadPoint2} {props.load.status === 'En route to 2nd LP' && props.load.etaTime && (
            <div className={classes.etaTime}>ETA: {props.load.etaTime}</div>
          )}</div>
          <div>
            <Input
              id="booking-time"
              elementType={'select'}
              elementConfig={{
                type: 'select',
                placeholder: 'Booking Time',
                options: Array.from({ length: 24 }, (_, i) => ({
                  value: i.toString().padStart(2, '0') + ":00",
                  displayValue: i.toString().padStart(2, '0') + ":00"
                }))
              }}
              value={props.load.bookingTime ?? ''}
              change={(e) => {
                initiateEdit('bookingTime', e.target.value, `Are you sure you want to set the booking time to {value}?`);
              }}
              inputStyle="main"
              labelHidden={true}
            />
          </div>

          {props.userType === 'admin' ? (
            <Input
              elementType="input"
              elementConfig={{
                type: 'text',
                placeholder: '',
              }}
              value={editState.field === 'bookingNumber' ? editState.value ?? '' : props.load.bookingNumber ?? ''}
              change={(event) => handleValueChange(event, 'bookingNumber')}
              id="booking-ref-input"
              label=""
              inputStyle="main"
              labelHidden={true}
              confirm={() => initiateEdit('bookingNumber', editState.value ?? '', 'Are you sure you want to update the booking number of this load to {value}?')}
            />
          ) : null}
          <div>{props.load.bookingRef}</div>
          <div className={props.isDuplicate ? classes.duplicate : ''}>{props.load.clientRef}</div>
          <div>{props.load.exporterRef}</div>
          <div>{props.load.isGenset === true ? 'YES' : 'NO'}</div>
          <div>{props.load.weighMethod}</div>
          <div>
            <Input
              elementType="input"
              elementConfig={{
                type: 'text',
                placeholder: '',
              }}
              value={editState.field === 'containerNumber' ? editState.value ?? '' : props.load.containerNumber ?? ''}
              change={(event) => handleValueChange(event, 'containerNumber')}
              id="container-number-input"
              label=""
              inputStyle="main"
              labelHidden={true}
              confirm={() => initiateEdit('containerNumber', editState.value ?? '', 'Are you sure you want to update the container number of this load to {value}?')}
            />
          </div>
          <div>
            <Input
              elementType="input"
              elementConfig={{
                type: 'text',
                placeholder: '',
              }}
              value={editState.field === 'sealNumber' ? editState.value ?? '' : props.load.sealNumber ?? ''}
              change={(event) => handleValueChange(event, 'sealNumber')}
              id="seal-number-input"
              label=""
              inputStyle="main"
              labelHidden={true}
              confirm={() => initiateEdit('sealNumber', editState.value ?? '', 'Are you sure you want to update the seal number of this load to {value}?')}
            />
          </div>
          <div>{props.load.temptaleType}</div>
          <div>
            <Input
              elementType="input"
              elementConfig={{
                type: 'text',
                placeholder: '',
              }}
              value={editState.field === 'temptaleNumber' ? editState.value ?? '' : props.load.temptaleNumber ?? ''}
              change={(event) => handleValueChange(event, 'temptaleNumber')}
              id="temptale-number-input"
              label=""
              inputStyle="main"
              labelHidden={true}
              confirm={() => initiateEdit('temptaleNumber', editState.value ?? '', 'Are you sure you want to update the temptale number of this load to {value}?')}
            />
          </div>
          <div>{props.load.tempCode}</div>
          <div>{props.load.tempSetting}</div>
          <div>{props.load.ventType}</div>
          <div>{props.load.commodity}</div>
          <div>{props.load.shippingLine}</div>
          <div>{props.load.vessel}</div>
          <div>{props.load.voyage}</div>
          <div>{props.load.dischargePort}</div>
          {props.loadStatus === 'completed' ? (
            <>
              <div>
                <Input
                  elementType="input"
                  elementConfig={{
                    type: 'currency',
                    placeholder: '',
                  }}
                  value={editState.field === 'clientPrice' ? editState.value ?? '' : (props.priceListPrice ?? props.load.clientPrice) ?? ''}
                  change={(event) => handleValueChange(event, 'clientPrice')}
                  id="invoice-input"
                  label=""
                  inputStyle="main"
                  labelHidden={true}
                  confirm={() => initiateEdit('clientPrice', editState.value ?? '', 'Are you sure you want to update the client price of this load to {value}?')}
                  key={`client-price-${props.load.id}`}
                />
              </div>

              <div>
                <Input
                  elementType="currency"
                  elementConfig={{
                    type: 'text',
                    placeholder: '',
                  }}
                  value={editState.field === 'subPrice' ? editState.value ?? '' : props.load.subPrice ?? ''}
                  change={(event) => handleValueChange(event, 'subPrice')}
                  id="invoice-input"
                  label=""
                  inputStyle="main"
                  labelHidden={true}
                  confirm={() => initiateEdit('subPrice', editState.value ?? '', 'Are you sure you want to update the sub price of this load to {value}?')}
                />
              </div>

              <div>
                <Input
                  elementType="input"
                  elementConfig={{
                    type: 'text',
                    placeholder: '',
                  }}
                  value={editState.field === 'invoiceNumber' ? editState.value ?? '' : props.load.invoiceNumber ?? ''}
                  change={(event) => handleValueChange(event, 'invoiceNumber')}
                  id="invoice-input"
                  label=""
                  inputStyle="main"
                  labelHidden={true}
                  confirm={() => initiateEdit('invoiceNumber', editState.value ?? '', 'Are you sure you want to update the invoice number of this load to {value}?')}
                />
              </div>
            </>
          ) : null}

          <div>{props.load.preAdvised === true ? 'YES' : 'NO'}</div>

          {props.userType !== 'client' && (
            <div className={classes['full-menu']}>
              <Dropdown isOpen={menuIsOpen} toggle={toggleMenu} direction={'down'} className={classes['dropdown']}>
                <DropdownToggle><CgMenu size={'16'} /></DropdownToggle>
                <DropdownMenu >
                  <DropdownItem onClick={() => props.showAdditionalCharges(props.load)}>Additional Charges</DropdownItem>
                  <DropdownItem onClick={() => props.showDocuments(props.load)}>Documents</DropdownItem>
                  <DropdownItem onClick={() => props.edit(props.load)}>Edit</DropdownItem>
                  <DropdownItem onClick={() => props.deleteLoad(props.load.id ?? '')}>Delete</DropdownItem>
                  <DropdownItem onClick={() => props.createLoad({
                    ...props.load,
                    clientRef: undefined,
                    bookingRef: undefined,
                    exporterRef: undefined
                  })}>Duplicate</DropdownItem>

                  {props.load.loadStatus === 'completed' ? (
                    <>
                      <DropdownItem onClick={() => props.showInvoicing(props.load)}>Invoicing</DropdownItem>
                      <DropdownItem onClick={() => props.unCompleteLoad(
                        props.load.id ?? '',
                        props.load.driver == null ? 'unassigned' : 'assigned'
                      )}>Move to Assigned</DropdownItem>
                    </>
                  ) : (
                    <>
                      {props.load.loadStatus === 'assigned' ? (<DropdownItem onClick={
                        () => props.plugLoad(props.load.id ?? '')}>Plug Container</DropdownItem>) : null}

                      <DropdownItem onClick={
                        () => props.completeLoad(props.load.id ?? '')}>Mark as Completed</DropdownItem>
                    </>
                  )}
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
        </div>
      ) : (
        <Row className={[classes[props.shade], 'd-flex', classes['load-item'], 'g-0', 'py-1'].join(' ')}>
          {props.userType !== 'client' && (
            <>
              <Col className={[classes['equal-width-columns'], 'd-flex'].join(' ')}>
                <div>{props.load.client}{props.load.handler ? ` (${props.load.handler.firstName})` : ''}</div>
              </Col>

              <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                {props.loadStatus === 'completed' ? (<div>{props.load.driver?.alias ?? null}</div>) : (
                  <Input
                    elementType="select"
                    allowEmpty={true}
                    elementConfig={{
                      type: 'select',
                      placeholder: 'Driver',
                      options: [
                        ...props.drivers.drivers.map(
                          driver => ({
                            value: {
                              alias: driver.alias,
                              phoneNumber: driver.phoneNumber,
                              id: driver.id
                            }, displayValue: driver.alias
                          }))
                      ]
                    }}
                    value={props.load.driver?.alias ?? ''}
                    change={(e) => {
                      initiateEdit(
                        'driver',
                        e.target.value,
                        `Are you sure you want to assign this load to {value}?`,
                        e.target.value.alias
                      );
                    }}
                    id="driver-input"
                    inputStyle="main"
                    labelHidden={true}
                  />
                )}
              </Col>
            </>
          )}

          {props.loadStatus === 'plugged' ? (
            <>
              <Col className={[
                props.userType === 'admin' ? classes['hide-mobile'] : '',
                props.userType === 'admin' ? classes['hide-tablet'] : '',
                classes['equal-width-columns']
              ].join(' ')}>
                {props.userType === 'admin' ? (
                  <div onClick={() => setShowDatePluggedPicker(true)} className='pointer d-flex'>
                    <div><FaCalendar color='var(--theme-blue)' /></div>
                    <div className='px-1'></div>
                    <div>{props.load.datePlugged !== '' && props.load.datePlugged != null ? new Date(props.load.datePlugged).toDateString() : null}</div>
                  </div>
                ) : (
                  <div>{props.load.datePlugged !== '' && props.load.datePlugged != null ? new Date(props.load.datePlugged).toDateString() : null}</div>
                )}
              </Col>

              <Col className={[
                props.userType === 'admin' ? classes['hide-mobile'] : '',
                props.userType === 'admin' ? classes['hide-tablet'] : '',
                classes['equal-width-columns']
              ].join(' ')}>
                {props.userType === 'admin' ? (
                  <div onClick={() => setShowXHauledDatePicker(true)} className='pointer d-flex'>
                    <div><FaCalendar color='var(--theme-blue)' /></div>
                    <div className='px-1'></div>
                    <div>{props.load.dateXHauled !== '' && props.load.dateXHauled != null ? new Date(props.load.dateXHauled).toDateString() : null}</div>
                  </div>
                ) : (
                  <div>{props.load.dateXHauled !== '' && props.load.dateXHauled != null ? new Date(props.load.dateXHauled).toDateString() : null}</div>
                )}
              </Col>

              <Col className={[classes['hide-tablet'], classes['hide-mobile'], classes['equal-width-columns']].join(' ')}>
                <div>{props.load.containerNumber}</div>
              </Col>

              <Col className={[classes['hide-tablet'], classes['hide-mobile'], classes['equal-width-columns']].join(' ')}>
                <div>{props.load.vessel}</div>
              </Col>
            </>
          ) : (
            <>
              <Col className={[classes['equal-width-columns']].join(' ')}>
                <div>{props.load.loadDate !== '' && props.load.loadDate != null ? new Date(props.load.loadDate).toDateString() : null}</div>
              </Col>
              {props.userType === 'client' ? null : (
                <Col className={[classes['hide-tablet'], classes['hide-mobile'], classes['equal-width-columns']].join(' ')}>
                  <div className='d-flex'>
                    {isUploadingBookingPhoto ? <div><Loader size='tiny' /></div> : <>
                      <Input
                        elementType="input"
                        elementConfig={{
                          type: 'text',
                          placeholder: '',
                        }}
                        value={editState.field === 'bookingNumber' ? editState.value ?? '' : props.load.bookingNumber ?? ''}
                        change={(event) => handleValueChange(event, 'bookingNumber')}
                        id="booking-ref-input"
                        label=""
                        inputStyle="main"
                        labelHidden={true}
                        confirm={() => initiateEdit('bookingNumber', editState.value ?? '', 'Are you sure you want to update the booking number of this load to {value}?')}
                      /> <FaUpload
                        className={classes['inline-icon']}
                        onClick={() => fileInputRef.current.click()}
                        size={18}
                        style={{
                          color: props.load.hasBookingPhoto === true ? 'var(--theme-green)' : ''
                        }}
                      />
                    </>}
                  </div>
                </Col>)}
            </>
          )}

          {props.loadStatus !== 'plugged' ? (
            <Col className={[
              props.userType === 'admin' ? classes['hide-mobile'] : '',
              props.userType === 'admin' ? classes['hide-tablet'] : '',
              classes['equal-width-columns']
            ].join(' ')}>
              <div>{props.load.bookingRef}</div>
            </Col>
          ) : null}

          <Col className={[
            props.userType === 'admin' ? classes['hide-mobile'] : '',
            props.userType === 'admin' ? classes['hide-tablet'] : '',
            classes['equal-width-columns']
          ].join(' ')}>
            <div className={props.isDuplicate ? classes.duplicate : ''}>{props.load.clientRef}</div>
          </Col>


          {props.loadStatus !== 'completed' && props.loadStatus !== 'plugged' ? (
            <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
              <div>{props.load.vessel}</div>
            </Col>
          ) : null}

          {props.loadStatus === 'unassigned' ? (
            <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
              <div>{props.load.emptyDepot}</div>
            </Col>
          ) : null}

          {props.loadStatus === 'plugged' ? (
            <>
              <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                {props.userType === 'admin' ? (
                  <Input
                    elementType="select"
                    allowEmpty={true}
                    elementConfig={{
                      type: 'select',
                      placeholder: 'Plugin Depot',
                      options: props.pluginDepots.pluginDepots.map(pluginDepot => ({
                        value: pluginDepot.alias,
                        displayValue: pluginDepot.alias,
                      })),
                    }}
                    value={props.load.pluginDepot ?? ''}
                    change={(e) => {
                      initiateEdit('pluginDepot', e.target.value, `Are you sure you want to set the plugin depot to {value}?`);
                    }}
                    id="plugin-depot-input"
                    inputStyle="main"
                    labelHidden={true}
                  />
                ) : (
                  <div>{props.load.pluginDepot}</div>
                )}
              </Col>
              {props.userType === 'admin' && (
                <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                  <Input
                    elementType="input"
                    elementConfig={{
                      type: 'text',
                      placeholder: '',
                    }}
                    value={editState.field === 'bookingNumber' ? editState.value ?? '' : props.load.bookingNumber ?? ''}
                    change={(event) => handleValueChange(event, 'bookingNumber')}
                    id="booking-ref-input"
                    label=""
                    inputStyle="main"
                    labelHidden={true}
                    confirm={() => initiateEdit('bookingNumber', editState.value ?? '', 'Are you sure you want to update the booking number of this load to {value}?')}
                  />
                </Col>
              )}

              <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                <div className={classes.checkboxes}>
                  <Input
                    elementType="checkbox"
                    allowEmpty={false}
                    elementConfig={{}}
                    value={props.load.preAdvised || props.load.dummyPreAdvised ? 'YES' : 'NO'}
                    change={(e) => {
                      initiateEdit('preAdvised', e.target.value, `Are you sure you want to set the pre-advised to {value}?`);
                    }}
                    id="advised-input"
                    isHalfTicked={props.load.dummyPreAdvised}
                    inputStyle="main"
                    labelHidden={true}
                    disabled={props.userType === 'client' && user?.permissions?.setPreAdvised !== true}
                  />
                </div>
              </Col>

              <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                {props.userType === 'admin' ? (
                  <Input
                    elementType="select"
                    allowEmpty={true}
                    elementConfig={{
                      type: 'select',
                      placeholder: 'Account',
                      options: props.accounts.accounts.map(account => ({
                        value: account.alias,
                        displayValue: account.alias,
                      })),
                    }}
                    value={props.load.account ?? ''}
                    change={(e) => {
                      initiateEdit('account', e.target.value, `Are you sure you want to set the account to {value}?`);
                    }}
                    id="account-input"
                    inputStyle="main"
                    labelHidden={true}
                  />
                ) : (
                  <div>{props.load.account}</div>
                )}
              </Col>
            </>
          ) : (
            <>
              <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                <div>{props.load.loadPoint1} {props.load.status === 'EN ROUTE TO 1st LP' && props.load.etaTime && (
                  <div className={classes.etaTime}>ETA: {props.load.etaTime}</div>
                )}</div>
              </Col>

              <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                <div>{props.load.loadPoint2} {props.load.status === 'EN ROUTE TO 2nd LP' && props.load.etaTime && (
                  <div className={classes.etaTime}>ETA: {props.load.etaTime}</div>
                )}</div>
              </Col>
            </>
          )}

          {props.loadStatus !== 'plugged' ? (
            <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
              <div className={classes.checkboxes}>
                <Input
                  elementType="checkbox"
                  allowEmpty={false}
                  elementConfig={{}}
                  value={props.load.isGenset ? 'YES' : 'NO'}
                  change={(e) => {
                    initiateEdit('isGenset', e.target.value, `Are you sure you want to set the genset to {value}?`);
                  }}
                  id="genset-input"
                  inputStyle="main"
                  labelHidden={true}
                  disabled={props.userType === 'client'}
                />

                {props.loadStatus === 'completed' && props.userType === 'admin' ? (
                  <Input
                    elementType="checkbox"
                    allowEmpty={false}
                    elementConfig={{}}
                    value={charges.length > 0 ? 'YES' : 'NO'}
                    change={(e) => { }}
                    id="charges-check"
                    inputStyle="main"
                    labelHidden={true}
                  />
                ) : null}
              </div>
            </Col>
          ) : null}

          {(props.loadStatus === 'assigned' || (props.loadStatus === 'completed' && props.userType === 'client')) ? (
            <>
              <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                <div>{props.load.containerNumber}</div>
              </Col>
              <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                <div>{props.load.sealNumber}</div>
              </Col>
            </>
          ) : null}

          {/* TODO: Move this to its own component */}
          {props.loadStatus === 'completed' && props.userType === 'admin' ? (
            <>
              <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                <div className={[classes['inline-input'], 'd-flex'].join(' ')}>
                  <Input
                    elementType="currency"
                    elementConfig={{
                      type: 'text',
                      placeholder: '',
                    }}
                    value={editState.field === 'clientPrice' ? editState.value ?? '' : (props.priceListPrice ?? props.load.clientPrice) ?? ''}
                    change={(event) => handleValueChange(event, 'clientPrice')}
                    id="invoice-input"
                    label=""
                    inputStyle="main"
                    labelHidden={true}
                    confirm={() => initiateEdit('clientPrice', editState.value ?? '', 'Are you sure you want to update the client price of this load to {value}?')}
                  />
                </div>
              </Col>

              <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                <div className={[classes['inline-input'], 'd-flex'].join(' ')}>
                  <Input
                    elementType="currency"
                    elementConfig={{
                      type: 'text',
                      placeholder: '',
                    }}
                    value={editState.field === 'subPrice' ? editState.value ?? '' : props.load.subPrice ?? ''}
                    change={(event) => handleValueChange(event, 'subPrice')}
                    id="invoice-input"
                    label=""
                    inputStyle="main"
                    labelHidden={true}
                    confirm={() => initiateEdit('subPrice', editState.value ?? '', 'Are you sure you want to update the sub price of this load to {value}?')}
                  />
                </div>
              </Col>

              <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                <div className={classes['inline-input']}>
                  <Input
                    elementType="input"
                    elementConfig={{
                      type: 'text',
                      placeholder: '',
                    }}
                    value={editState.field === 'containerNumber' ? editState.value ?? '' : props.load.containerNumber ?? ''}
                    change={(event) => handleValueChange(event, 'containerNumber')}
                    id="container-number-input"
                    label=""
                    inputStyle="main"
                    labelHidden={true}
                    confirm={() => initiateEdit('containerNumber', editState.value ?? '', 'Are you sure you want to update the container number of this load to {value}?')}
                  />
                </div>
              </Col>
              <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                <div className={[classes['inline-input'], 'd-flex'].join(' ')}>
                  <Input
                    elementType="input"
                    elementConfig={{
                      type: 'text',
                      placeholder: '',
                    }}
                    value={editState.field === 'invoiceNumber' ? editState.value ?? '' : props.load.invoiceNumber ?? ''}
                    change={(event) => handleValueChange(event, 'invoiceNumber')}
                    id="invoice-input"
                    label=""
                    inputStyle="main"
                    labelHidden={true}
                    confirm={() => initiateEdit('invoiceNumber', editState.value ?? '', 'Are you sure you want to update the invoice number of this load to {value}?')}
                  />
                </div>
              </Col>
            </>
          ) : null}

          {props.loadStatus === 'assigned' ? (
            <>
              <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                {props.userType === 'client' ? (<div>{props.load.status}</div>) : (<Input
                  elementType="select"
                  allowEmpty={true}
                  elementConfig={{
                    type: 'select',
                    placeholder: 'Load Status',
                    options: [
                      ...props.statuses.statuses.map(status => ({
                        value: status.alias,
                        displayValue: status.alias,
                      })),
                      { value: 'EN ROUTE TO 1st LP', displayValue: 'EN ROUTE TO 1st LP' },
                      { value: 'EN ROUTE TO 2nd LP', displayValue: 'EN ROUTE TO 2nd LP' },
                      { value: 'EN ROUTE TO PORT', displayValue: 'EN ROUTE TO PORT' }
                    ],
                  }}
                  value={props.load.status ?? ''}
                  change={(e) => {
                    const statusValue = e.target.value;
                    if (
                      statusValue === 'EN ROUTE TO 1st LP' ||
                      statusValue === 'EN ROUTE TO 2nd LP' ||
                      statusValue === 'EN ROUTE TO PORT'
                    ) {
                      setSelectedStatus(statusValue);
                      setShowETATimePicker(true);
                    } else {
                      initiateEdit('status', statusValue, `Are you sure you want to set the load status to {value}?`);
                    }
                  }}
                  id="status-input"
                  inputStyle="main"
                  labelHidden={true}
                />)}
              </Col>

              <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                {props.userType === 'client' ? (<div>{props.load.bookingTime}</div>) : (<Input
                  id="bookingTime"
                  elementType={'select'}
                  allowEmpty={true}
                  elementConfig={{
                    type: 'select',
                    placeholder: 'Booking Time',
                    options: Array.from({ length: 24 }, (_, i) => ({
                      value: i.toString().padStart(2, '0') + ":00",
                      displayValue: i.toString().padStart(2, '0') + ":00"
                    }))
                  }}
                  value={props.load.bookingTime ?? ''}
                  change={(e) => {
                    initiateEdit('bookingTime', e.target.value, `Are you sure you want to set the booking time to {value}?`);
                  }}
                  inputStyle="main"
                  labelHidden
                />)}
              </Col>

              <Col className={[classes['hide-mobile'], classes['hide-tablet']].join(' ')}>
                <div className={classes['checkboxes']} >
                  <Input
                    elementType="checkbox"
                    allowEmpty={false}
                    elementConfig={{}}
                    value={props.load.preAdvised || props.load.dummyPreAdvised ? 'YES' : 'NO'}
                    change={(e) => {
                      initiateEdit('preAdvised', e.target.value, `Are you sure you want to set the pre-advised to {value}?`);
                    }}
                    id="advised-input"
                    inputStyle="main"
                    labelHidden={true}
                    disabled={props.userType === 'client' && user?.permissions?.setPreAdvised !== true}
                    isHalfTicked={props.load.dummyPreAdvised}
                  />
                </div>
              </Col>
            </>
          ) : null}

          <Col className={[classes['fit-content-column'], 'd-flex'].join(' ')}>
            <Dropdown isOpen={menuIsOpen} toggle={toggleMenu} direction={'down'} className={classes['dropdown']}>
              <DropdownToggle><CgMenu size={'16'} /></DropdownToggle>
              <DropdownMenu >
                <DropdownItem onClick={() => props.showDocuments(props.load)}>Documents</DropdownItem>

                {props.userType === 'admin' && (
                  <>
                    <DropdownItem onClick={() => props.showAdditionalCharges(props.load)}>Additional Charges</DropdownItem>
                    <DropdownItem onClick={() => props.edit(props.load)}>Edit</DropdownItem>
                    <DropdownItem onClick={() => props.deleteLoad(props.load.id ?? '')}>Delete</DropdownItem>
                    <DropdownItem onClick={() => props.createLoad({
                      ...props.load,
                      clientRef: undefined,
                      bookingRef: undefined,
                      exporterRef: undefined
                    })}>Duplicate</DropdownItem>

                    {props.load.loadStatus === 'completed' ? (
                      <>
                        <DropdownItem onClick={() => props.unCompleteLoad(
                          props.load.id ?? '',
                          props.load.driver == null ? 'unassigned' : 'assigned'
                        )}>Move to Assigned</DropdownItem>
                        <DropdownItem onClick={() => props.showInvoicing(props.load)}>Invoicing</DropdownItem>
                      </>
                    ) : (
                      <>
                        {props.load.loadStatus === 'assigned' ? (<DropdownItem onClick={
                          () => props.plugLoad(props.load.id ?? '')}>Plug Container</DropdownItem>) : null}

                        <DropdownItem onClick={
                          () => props.completeLoad(props.load.id ?? '')}>Mark as Completed</DropdownItem>
                      </>
                    )}
                  </>
                )}
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row >
      )}
    </>
  );
};

export default LoadItem;