import React from 'react';
import classes from './HeaderRight.module.scss';
import Button from '../../UI/Button/Button';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';

interface HeaderRightProps {
  logout: () => void;
}

const HeaderRight: React.FC<HeaderRightProps> = (props) => {
  const user = useAppSelector(state => state.auth.user)

  return (
    <div className={classes['header-right']}>
      {user?.userType !== 'driver' ? (
        <>
          <div className={classes['link-box']}>
            <NavLink to='/home' className={
              ({ isActive, isPending }) => isPending ? classes.pending : isActive ? classes.active : ""}>Home</NavLink>
          </div>

          <div className={classes['link-box']}>
            <NavLink to='/loads' className={
              ({ isActive, isPending }) => isPending ? classes.pending : isActive ? classes.active : ""}>Loads</NavLink>
          </div>
        </>
      ) : null}

      {user?.userType === 'admin' ? (
        <div className={classes['link-box']}>
          <NavLink to='/admin' className={
            ({ isActive, isPending }) => isPending ? classes.pending : isActive ? classes.active : ""}>Admin</NavLink>
        </div>
      ) : null}

      {user?.userType === 'client' && user?.permissions?.viewPriceList === true ? (
        <div className={classes['link-box']}>
          <NavLink to='/price-list' className={
            ({ isActive, isPending }) => isPending ? classes.pending : isActive ? classes.active : ""}>Price List</NavLink>
        </div>
      ) : null}

      <Button
        buttonStyle='main'
        type='button'
        text='Logout'
        click={props.logout}
      />
    </div >
  );
};

export default HeaderRight;