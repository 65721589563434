import React from 'react';
import { Document, Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import logo from '../../../../assets/images/logo.png';

interface PriceListPdfProps {
  clientName?: string;
  loadPoints: Array<{ id: string; alias: string }>;
  priceItems: any;
  fuelLevyPercentage: number;
  selectedDate: string;
}

const styles = StyleSheet.create({
  page: {
    fontSize: '12px'
  },
  outer: {
    margin: '15px',
    border: '1px solid black'
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    borderBottom: '1px solid black',
    paddingBottom: '10px'
  },
  image: {
    height: 'auto',
    width: '280px',
    marginLeft: '30px',
    marginTop: '30px'
  },
  title: {
    textAlign: 'center',
    padding: '10px',
    fontSize: '16px',
    backgroundColor: 'rgb(240,240,240)'
  },
  row: {
    borderTop: '1px solid black',
    display: 'flex',
    flexDirection: 'row'
  },
  cell: {
    width: '50%',
    padding: '8px',
    textAlign: 'center',
    borderRight: '1px solid black'
  },
  headerCell: {
    backgroundColor: 'rgb(240,240,240)',
    fontWeight: 'bold'
  },
  sectionTitle: {
    padding: '15px 8px',
    fontSize: '14px',
    backgroundColor: 'rgb(240,240,240)',
    borderTop: '1px solid black'
  }
});

const PriceListPdf: React.FC<PriceListPdfProps> = ({
  clientName,
  loadPoints,
  priceItems,
  fuelLevyPercentage,
  selectedDate
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.outer}>
        <View style={styles.header}>
          <Image src={logo} style={styles.image} />
        </View>

        <View style={styles.title}>
          <Text>Price List - {clientName}</Text>
          <Text style={{ fontSize: '12px', marginTop: '5px' }}>Valid from: {selectedDate}</Text>
        </View>

        <View style={styles.row}>
          <View style={{ ...styles.cell, ...styles.headerCell }}>
            <Text>Fuel Levy Percentage</Text>
          </View>
          <View style={styles.cell}>
            <Text>{fuelLevyPercentage}%</Text>
          </View>
        </View>

        <View style={styles.sectionTitle}>
          <Text>Load Point Rates</Text>
        </View>

        <View style={styles.row}>
          <View style={{ ...styles.cell, ...styles.headerCell }}>
            <Text>Load Point</Text>
          </View>
          <View style={{ ...styles.cell, ...styles.headerCell }}>
            <Text>Rate</Text>
          </View>
        </View>

        {loadPoints.map((loadPoint) => (
          priceItems[loadPoint.alias] && (
            <View key={loadPoint.id} style={styles.row}>
              <View style={styles.cell}>
                <Text>{loadPoint.alias}</Text>
              </View>
              <View style={styles.cell}>
                <Text>
                  R {priceItems.netOverrides?.[loadPoint.alias]
                    ? (priceItems.netOverrides[loadPoint.alias]).toFixed(0)
                    : (priceItems[loadPoint.alias] * (1 + fuelLevyPercentage / 100)).toFixed(0)
                  }
                </Text>
              </View>
            </View>
          )
        ))}

        <View style={styles.sectionTitle}>
          <Text>Additional Charges</Text>
        </View>

        {Object.entries(priceItems.additionalCharges || {}).map(([key, value]: [string, any]) => (
          <View key={key} style={styles.row}>
            <View style={styles.cell}>
              <Text>
                {key
                  .replace(/([A-Z])/g, ' $1')
                  .replace(/^./, str => str.toUpperCase())
                  .trim()}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text>
                {key === 'dualLoadOffRoute'
                  ? `${value}%`
                  : `R ${value.toFixed(0)}`
                }
              </Text>
            </View>
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

export default PriceListPdf; 