import React, { FC, ReactNode } from 'react';
import Button from '../Button/Button';
import classes from './Alert.module.scss';
import Axl from '../../../containers/Axl';
import Modal from '../../../containers/Modal/Modal';

interface AlertProps {
  isOpen: boolean;
  message: string | ReactNode;
  isConfirm?: boolean;
  confirm?: (confirmValue: boolean, optionIndex?: number) => void;
  ok?: () => void;
  options?: string[];
}

const Alert: FC<AlertProps> = (props) => {
  if (!props.isOpen) return null;

  return (
    <Modal className={classes.modal} zIndex={9999}>
      <div>
        <span>{props.message}</span>
      </div>

      <div className={classes.footer}>
        {(props.options?.length ?? 0) > 0 ? (
          <Axl>
            <div className='mt-3'>
              <Button
                type='button'
                buttonStyle='main'
                text='Cancel'
                click={() => props.confirm && props.confirm(false)}
              />
            </div>

            {props.options?.map((option, index) => (
              <div className='mt-3'>
                <Button
                  key={index}
                  type='button'
                  buttonStyle='main'
                  text={option}
                  click={() => props.confirm && props.confirm(true, index)}
                />
              </div>
            ))}
          </Axl>
        ) : null}

        {props.isConfirm && (props.options?.length ?? 0) === 0 ? (
          <Axl>
            <Button
              type='button'
              buttonStyle='main'
              text='Cancel'
              click={() => props.confirm && props.confirm(false)}
            />
            <Button
              type='button'
              buttonStyle='main'
              text='Yes'
              click={() => props.confirm && props.confirm(true)}
            />
          </Axl>
        ) : null}

        {!props.isConfirm ? (
          <Axl>
            <Button
              type='button'
              buttonStyle='main'
              text='Ok'
              click={props.ok}
            />
          </Axl>
        ) : null}
      </div>
    </Modal>
  );
};

export default Alert;
